import { loadModel, getColors } from 'vmfcom-lib/utils';
import InView from '../../../Shared/InView';
import Styles from './FloatingText.styles';
import createClassList from '../../../../util/createClassList';
import theme from '../../../../styles/theme';
import React from 'react';
import sa from '../../../../styles/Components/SimpleAnimation';
import ReactMarkdown from 'react-markdown';
import Link from '../Link';

const FloatingText = ({ $data = {}, className = '' }) => {
  const primaryClass = 'floating-text';
  const content = loadModel($data, 'VMFCom', 'FloatingText');

  return (
    <InView>
      <Styles
        className={createClassList(primaryClass, className)}
        primaryClass={primaryClass}
        backgroundColor={
          getColors(content?.backgroundColor).primary || theme.colors.white
        }
        textColor={
          getColors(content?.floatingTextColor).primary || theme.colors.white
        }
        $data={content}
      >
        <div className={`${primaryClass}`}>
          <div className={`${primaryClass}__content`}>
            {content?.floatingText && (
              <sa.div className={`${primaryClass}__content__text`}>
                <ReactMarkdown source={content?.floatingText} />
              </sa.div>
            )}
            {content?.floatingLinks?.length && (
              <div className={`${primaryClass}__content__links`}>
                {content.floatingLinks.map(link => {
                  return <Link $data={link} />;
                })}
              </div>
            )}
          </div>
        </div>
      </Styles>
    </InView>
  );
};

export default FloatingText;
