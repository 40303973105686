import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const noop = () => {};
const selectorBarHeight = 34;

const Gradient = styled.div`
  position: absolute;
  width: 100%;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  top: 0;
  background: linear-gradient(
    ${props => props.theme.colors.grey6},
    transparent,
    ${props => props.theme.colors.grey6}
  );
  z-index: 3;
  pointer-events: none;
`;

const SelectorBar = styled.div`
  position: absolute;
  width: 100%;
  width: ${props => props.width}px;
  height: ${selectorBarHeight}px;
  top: ${props => props.top}px;
  background: white;
  z-index: 1;
  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 325px;
  width: 100%;
`;

const Styles = styled.div`
  * {
    box-sizing: border-box;
  }
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  height: ${props => props.height}px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Casper = styled.div`
  width: 100%;
  height: ${props => props.sizey}px;
`;
const Element = ({ child, children, ...props }) =>
  React.cloneElement(child, props, children);

const Child = ({ child, children, snapTarget, ...props }) => {
  return (
    <Element
      child={child}
      data-snappable='true'
      data-snaptarget={`${snapTarget}`}
      {...props}
    >
      {children}
    </Element>
  );
};

const Scrollable = React.forwardRef(
  (
    {
      onScrollStart = noop,
      onScrollEnd = noop,
      onAtCasper = noop,
      onSnap = noop,
      snapValue = null,
      setSnapValue = noop,
      snapTo = 'children',
      snapToNearest = 'center',
      snapToViewportPosition = 'center',
      displayChildrenMax = 4,
      pollRate = 100,
      scrollPauseDelay = 100,
      children,
      ...props
    },
    forwardedRef
  ) => {
    // Refs
    const internalRef = useRef(null);
    const ref = forwardedRef || internalRef;

    // State
    const [isAtTopOrBottom, setIsAtTopOrBottom] = useState({
      top: false,
      bottom: false,
    });
    const [actualChildren, setActualChildren] = useState([]);
    const [snappedTarget, setSnappedTarget] = useState(null);
    const [isScrolling, setIsScrolling] = useState(null);
    const [parentHeight, setParentHeight] = useState(0);
    const [componentReady, setComponentReady] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    // State refs
    const shouldListenToScroll = useRef(false);
    const lastScroll = useRef(null);

    // Timers
    const scrollStateTimer = useRef(null);
    const pauseDelayTimer = useRef(null);

    // Memos
    const onChildClick = useMemo(() => {
      return (snapTargetValue, callback) => {
        snapToTarget(snapTargetValue);
        if (callback) callback();
      };
    }, [actualChildren]);

    const SelectorBarTop = useMemo(() => {
      let top = 0;
      if (selectorBarHeight > 0 && parentHeight > 0) {
        if (snapToViewportPosition === 'center') top = parentHeight / 2;
        else if (snapToViewportPosition === 'bottom') top = parentHeight;

        if (snapToNearest === 'center') top -= selectorBarHeight / 2;
        else if (snapToNearest === 'bottom') top -= selectorBarHeight;
      }
      return top;
    }, [parentHeight, snapToViewportPosition, snapToNearest]);

    // Generate children with data attributes to access DOM properties
    const customChildren = useMemo(() => {
      return (
        <>
          {children.map((child, index) => {
            const rand = (Math.random() + 1).toString(36).substring(2);
            return (
              <Child
                key={`child-${index}-${rand}`}
                snapTarget={`${index}`}
                child={child}
                children={child.props.children}
                onClick={() => onChildClick(index, child.props.onClick)}
              />
            );
          })}
        </>
      );
    }, [onChildClick]);

    const casperYSizes = useMemo(() => {
      let topYSize = 0;
      let bottomYSize = 0;

      if (
        parentHeight &&
        actualChildren.length &&
        actualChildren[0].offsetHeight
      ) {
        if (
          snapToViewportPosition === 'top' ||
          snapToViewportPosition === null
        ) {
          bottomYSize = parentHeight;
        } else if (snapToViewportPosition === 'center') {
          topYSize = parentHeight / 2;
          bottomYSize = parentHeight / 2;
        } else if (snapToViewportPosition === 'bottom') {
          topYSize = parentHeight;
        }

        if (snapToNearest === 'top' || snapToNearest === null) {
          bottomYSize -= actualChildren[actualChildren.length - 1].offsetHeight;
        } else if (snapToNearest === 'center') {
          topYSize -= topYSize > 0 ? actualChildren[0].offsetHeight / 2 : 0;
          bottomYSize -=
            bottomYSize > 0 ? actualChildren[0].offsetHeight / 2 : 0;
        } else if (snapToNearest === 'bottom') {
          topYSize -= topYSize > 0 ? actualChildren[0].offsetHeight : 0;
        }
      }

      return {
        top: topYSize,
        bottom: bottomYSize,
      };
    }, [parentHeight, actualChildren, snapToViewportPosition, snapToNearest]);

    // Effects
    // Check if at top or bottom of content
    useEffect(() => {
      if (casperYSizes.top || casperYSizes.bottom) setAtTopOrBottomValue();
    }, [casperYSizes]);

    useEffect(() => {
      onAtCasper(isAtTopOrBottom);
    }, [isAtTopOrBottom]);

    // Listen for resize events on ref and set height and width state
    useEffect(() => {
      const resizeObserver = new ResizeObserver(entries => {
        const parent = entries[0].target;
        const parentHeight = [...parent.children]
          .filter(f => !f.dataset.iscasper && !f.dataset.isnull)
          .slice(0, displayChildrenMax)
          .reduce((p, c) => (p += c.offsetHeight), 0);
        setParentHeight(parentHeight);
      });

      if (ref.current) {
        resizeObserver.observe(ref.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    useEffect(() => {
      if (snappedTarget !== null && typeof snappedTarget === 'number') {
        const nodes = [...document.querySelectorAll('[data-snappable]')];
        const foundIndex = nodes.findIndex(
          f => f.dataset.snaptarget === `${snappedTarget}`
        );
        onSnap({
          target: nodes[foundIndex],
          data: nodes[foundIndex].dataset,
          childIndex: foundIndex,
        });
      }
    }, [snappedTarget]);

    // Set component ready state
    useEffect(() => {
      if (parentHeight && actualChildren && !componentReady) {
        setComponentReady(true);
      }
    }, [parentHeight, actualChildren]);

    // Snap when snap value changes
    useEffect(() => {
      if (!isScrolling && componentReady && actualChildren?.length) {
        if (snappedTarget === null) {
          if (snapValue === null) {
            snapToTarget(0);
          } else {
            snapToTarget(snapValue);
          }
        } else {
          if (snapValue !== snappedTarget) {
            snapToTarget(snapValue);
          }
        }
      }
    }, [componentReady, parentHeight, snapValue, actualChildren, isScrolling]);

    // Listen for scroll
    useEffect(() => {
      if (componentReady && parentHeight) {
        if (isScrolling === true) {
          onScrollStart();
        } else if (isScrolling === false) {
          onScrollEnd();

          if (snapTo) {
            if (snapTo === 'children') {
              if (!isTouched) {
                if (snappedTarget !== null) {
                  const nearest = getNearestChild();
                  if (nearest.snapTarget !== snappedTarget) {
                    snapToTarget(nearest.snapTarget);
                  }
                }
              }
            } else if (typeof snapTo === 'number') {
              if (snappedTarget !== null) {
                snapToTarget(snapTo);
              }
            } else {
              console.error('Invalid snapTo prop');
            }
          }
        }
      }
    }, [componentReady, parentHeight, isScrolling, snappedTarget]);

    // Set Actual Children
    useEffect(() => {
      let i = null;

      i = setInterval(() => {
        const childrenNodes = getChildrenNodes();
        if (childrenNodes.length > 0) {
          setActualChildren(childrenNodes);
          clearInterval(i);
          i = null;
        }
      }, 50);

      return () => {
        if (i) {
          clearInterval(i);
          i = null;
        }
      };
    }, [children]);

    // Handle Touch End Event During Scroll
    useEffect(() => {
      if (!isTouched && !isScrolling) {
        const nearestChild = getNearestChild();
        snapToTarget(nearestChild.snapTarget);
      }
    }, [isTouched, isScrolling]);

    const getChildrenNodes = () => {
      return [...document.querySelectorAll('[data-snappable]')];
    };

    const snapToTarget = async value => {
      const nodes = [...document.querySelectorAll('[data-snappable]')];
      const elem = nodes.find(f => +f.dataset.snaptarget === value);
      const precedingElements = nodes.slice(0, +elem.dataset.snaptarget);
      const targetTop =
        casperYSizes.top +
        precedingElements.reduce((p, c) => (p += c.offsetHeight), 0);
      let snapToPx = targetTop;

      if (snapToViewportPosition === 'center') {
        snapToPx =
          snapToPx - ref.current.offsetHeight / 2 + elem.offsetHeight / 2;
      } else if (snapToViewportPosition === 'bottom') {
        snapToPx = snapToPx - ref.current.offsetHeight + elem.offsetHeight;
      }

      shouldListenToScroll.current = false;
      ref.current.scrollTo({
        behavior: 'smooth',
        top: snapToPx,
      });
      await waitForScrollingToStop();
      setIsScrolling(false);
      setSnappedTarget(value);
      shouldListenToScroll.current = true;
    };

    const waitForScrollingToStop = () =>
      new Promise((resolve, reject) => {
        lastScroll.current = null;

        if (scrollStateTimer.current) {
          clearInterval(scrollStateTimer.current);
          scrollStateTimer.current = null;
        }

        const determineIfScrolling = () => {
          const currentScroll = ref.current.scrollTop;
          if (lastScroll.current === null) {
            lastScroll.current = currentScroll;
            return;
          }

          if (currentScroll === lastScroll.current) {
            clearInterval(scrollStateTimer.current);
            scrollStateTimer.current = null;
            resolve(currentScroll);
          } else {
            lastScroll.current = currentScroll;
          }
        };

        determineIfScrolling();
        scrollStateTimer.current = setInterval(determineIfScrolling, pollRate);
      });

    const startListeningToScrollState = async (shouldSetState = true) => {
      if (shouldSetState) setIsScrolling(true);
      await waitForScrollingToStop();
      pauseListeningToScrollState(shouldSetState);
    };

    const pauseListeningToScrollState = (shouldSetState = true) => {
      lastScroll.current = ref.current.scrollTop;

      if (pauseDelayTimer.current) {
        clearTimeout(pauseDelayTimer.current);
        pauseDelayTimer.current = null;
      }

      const determineIfScrollingResumed = () => {
        if (lastScroll.current !== ref.current.scrollTop) {
          startListeningToScrollState(false);
        } else {
          stopListeningToScrollState(shouldSetState);
        }
      };

      pauseDelayTimer.current = setTimeout(
        determineIfScrollingResumed,
        scrollPauseDelay
      );
    };

    const stopListeningToScrollState = () => {
      lastScroll.current = null;
      setIsScrolling(false);
    };

    const getNearestChild = () => {
      const currentScroll = ref.current.scrollTop;
      const nodes = [...document.querySelectorAll('[data-snappable]')];

      let parentTarget = currentScroll;
      if (snapToViewportPosition === 'center')
        parentTarget = currentScroll + parentHeight / 2;
      else if (snapToViewportPosition === 'bottom')
        parentTarget = currentScroll + parentHeight;

      const closestChildren = nodes
        .map((child, index) => {
          const childTop = child.offsetTop - ref.current.offsetTop;
          const coord = {
            top: childTop,
            center: childTop + child.offsetHeight / 2,
            bottom: childTop + child.offsetHeight,
          };

          let childTarget = coord.top;
          if (snapToNearest === 'center') childTarget = coord.center;
          else if (snapToNearest === 'bottom') childTarget = coord.bottom;

          const diff = Math.abs(parentTarget - childTarget);
          return {
            child,
            snapTarget: +child.dataset.snaptarget,
            coord,
            diff,
          };
        })
        .sort((a, b) => (a.diff < b.diff ? -1 : 1));

      return closestChildren[0];
    };

    const setAtTopOrBottomValue = e => {
      const isAtTop = ref.current.scrollTop <= casperYSizes.top;
      const isAtBottom =
        ref.current.scrollTop >=
        ref.current.scrollHeight - parentHeight - casperYSizes.bottom;
      const output = {
        top: isAtTop,
        bottom: isAtBottom,
      };

      if (
        output.top !== isAtTopOrBottom.top ||
        output.bottom !== isAtTopOrBottom.bottom
      ) {
        setIsAtTopOrBottom(output);
      }
    };

    const handleScroll = e => {
      if (casperYSizes.top || casperYSizes.bottom) setAtTopOrBottomValue(e);

      if (isScrolling === null) {
        shouldListenToScroll.current = true;
      }

      if (shouldListenToScroll.current) {
        if (!isScrolling) {
          startListeningToScrollState();
        }
      }
    };

    const handleTouchStart = () => {
      if (props.onTouchStart) props.onTouchStart();
      setIsTouched(true);
    };
    const handleTouchEnd = () => {
      if (props.onTouchEnd) props.onTouchEnd();
      setIsTouched(false);
    };

    return (
      <Wrapper>
        <Styles
          {...props}
          onScroll={handleScroll}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          ref={ref}
          height={parentHeight}
        >
          <Casper sizey={casperYSizes.top} data-iscasper='true' />
          {customChildren}
          <Casper sizey={casperYSizes.bottom} data-iscasper='true' />
        </Styles>
        <Gradient height={parentHeight} />
        <SelectorBar top={SelectorBarTop} />
      </Wrapper>
    );
  }
);

Scrollable.propTypes = {
  onScrollStart: PropTypes.func,
  onScrollEnd: PropTypes.func,
  onAtCasper: PropTypes.func,
  snapValue: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  snapTo: PropTypes.oneOf(['children', PropTypes.number]),
  snapIgnore: PropTypes.arrayOf(PropTypes.number),
  snapToNearest: PropTypes.oneOf(['top', 'bottom', 'center', PropTypes.number]),
  snapToViewportPosition: PropTypes.oneOf(['top', 'bottom', 'center']),
  displayChildrenMax: PropTypes.number,
  pollRate: PropTypes.number,
  scrollPauseDelay: PropTypes.number,
};

export default Scrollable;
