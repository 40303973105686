import React, { useState } from 'react';
import Styles from './VisualMedia.styled';
import Picture from '../../../Shared/Picture';
import PlayButtonCircle from '../../../PlayButtonCircle/PlayButtonCircle';
import Modal from '../../../Shared/Modal';
import Video from '../../../Shared/Video';
import theme from '../../../../styles/theme';

const VisualMedia = ({ $data, className }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };
  return (
    <Styles className={className}>
      {$data?.videoId && $data?.videoSource ? (
        <div className='hero-video-wrapper'>
          <div className='hero-video'>
            <Picture
              src={`${$data?.image?.file?.url}`}
              alt={$data?.image?.description}
              sources={[
                {
                  media: `(min-width: ${theme.breakpoints.desktop})`,
                  params: '?w=626',
                  srcSet: $data?.image?.file?.url,
                },
                {
                  media: `(min-width: ${theme.breakpoints.tablet})`,
                  params: '?w=512',
                  srcSet: $data?.imageTablet?.file?.url,
                },
                {
                  media: `(max-width: ${theme.breakpoints.tablet})`,
                  params: '?w=300',
                  srcSet: $data?.imageMobile?.file?.url,
                },
              ]}
              onClick={() => setModalOpen(true)}
              isResponsive
            >
              <PlayButtonCircle onClick={handleClick}></PlayButtonCircle>
            </Picture>
          </div>

          {modalOpen && (
            <Modal onClose={() => setModalOpen(false)}>
              <Video content={$data.videoId} source={$data.videoSource}></Video>
            </Modal>
          )}
        </div>
      ) : (
        <Picture
          src={`${$data?.image?.file?.url}`}
          alt={$data?.image?.description}
          sources={[
            {
              media: `(min-width: ${theme.breakpoints.desktop})`,
              params: '?w=626',
              srcSet: $data?.image?.file?.url,
            },
            {
              media: `(min-width: ${theme.breakpoints.tablet})`,
              params: '?w=512',
              srcSet: $data?.imageTablet?.file?.url,
            },
            {
              media: `(max-width: ${theme.breakpoints.tablet})`,
              params: '?w=300',
              srcSet: $data?.imageMobile?.file?.url,
            },
          ]}
          isResponsive
        ></Picture>
      )}
    </Styles>
  );
};

export default VisualMedia;
