import React from 'react';
import PropTypes from 'prop-types';
import noop from '../../util/noop';
import Styles from './PlayButtonCircle.styled';
import theme from '../../styles/theme';
import createClassList from '../../util/createClassList';

const PlayButtonCircle = ({
  onClick = noop,
  backgroundColor = theme.colors.white,
  iconColor = theme.colors.vmfBlue,
  className = '',
  ...props
}) => {
  const classList = createClassList(['play-button-circle', className]);

  return (
    <Styles
      onClick={onClick}
      backgroundColor={backgroundColor}
      className={classList}
      {...props}
    >
      <svg
        width='13'
        height='18'
        viewBox='0 0 13 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.8248 8.20679C12.3466 8.60706 12.3466 9.39337 11.8248 9.79364L1.79176 17.4903C1.13396 17.995 0.183098 17.526 0.183098 16.6969L0.183099 1.30359C0.183099 0.474532 1.13396 0.00554808 1.79176 0.510164L11.8248 8.20679Z'
          fill={iconColor}
        />
      </svg>
    </Styles>
  );
};

PlayButtonCircle.propTypes = {
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  iconColor: PropTypes.string,
};

export default PlayButtonCircle;
