import React from 'react';
import { getColors, loadModel } from 'vmfcom-lib/utils';
import FindAHomeCenter from '../../../FindAHomeCenter/FindAHomeCenter';
import CalculatorSection from '../../../CalculatorSection/CalculatorSection';

const SharedComponent = ({ $data }) => {
  const content = loadModel($data, 'VMFCom', 'SharedComponent');
  switch (content?.component) {
    case 'Find a Home Center':
      return (
        <FindAHomeCenter
          backgroundColor={getColors(content?.backgroundColor).primary}
          headerTextColor={getColors(content?.headerTextColor).primary}
          heading={content?.sectionHeader}
          showIllustrations={false}
        />
      );
    case 'Mortgage Calculator':
      return (
        <CalculatorSection
          backgroundColor={getColors(content?.backgroundColor).primary}
          headerTextColor={getColors(content?.headerTextColor).primary}
          header={content?.sectionHeader}
          disclaimer={content?.disclaimer}
        />
      );
    default:
      return <></>;
  }
};

export default SharedComponent;
