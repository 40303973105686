import React, { useState, useEffect, useMemo } from 'react';
import Styles, { LOModalStyles } from './styles';
import InteractiveMap from '../../../../InteractiveMap/InteractiveMap';
import Picker from '../../../../Picker/Picker';
import InView from '../../../../Shared/InView';
import Modal from '../../../../Shared/Modal';
import { getColors, loadModel } from 'vmfcom-lib/utils';

export const pickerPositions = {
  top: 'top',
  bottom: 'bottom',
};

const LOModal = ({ greeting, officer }) => {
  const link = useMemo(() => {
    if (officer?.uid && typeof window !== 'undefined' && window) {
      return (
        window.location.origin +
        window.location.pathname +
        (window?.location?.pathname?.slice(-1) !== '/' ? '/' : '') +
        'team/' +
        officer.uid
      );
    }

    return '#';
  }, [officer]);

  return (
    <LOModalStyles>
      <h2>{greeting}</h2>
      <a href={link}>View full profile</a>
      <div className='lo-modal__card'>
        <div className='lo-modal__card__image'>
          <img
            src={officer?.headshotPhoto?.fields?.file?.url}
            alt={officer?.headshotPhoto?.fields?.description}
          />
        </div>
        <div className='lo-modal__card__details'>
          <h3>
            {officer?.firstName} {officer?.lastName}
          </h3>
          <p>{officer?.jobTitle}</p>
          <p>NMLS #{officer?.individualNmls}</p>
          <p>
            {officer?.streetAddress}, {officer?.city}, {officer?.state}{' '}
            {officer?.zipCode}
          </p>
          <a href={`mailto:${officer?.email}`}>{officer?.email}</a>
          <a href={`tel:${officer?.phoneNumber}`}>{officer?.phoneNumber}</a>
        </div>
      </div>
    </LOModalStyles>
  );
};

const IndividualLicenseMapSection = ({
  $data,
  businessCards = [],
  showTooltips = false,
  pickerPosition = pickerPositions.top,
  displayLicenseNumbersInPicker = true,
  onStateClick = () => {},
  ...props
}) => {
  const [selectedLoanOfficer, setSelectedLoanOfficer] = useState(null);
  const [selectedState, setSelectedState] = useState('all');
  const [modalOfficer, setModalOfficer] = useState(null);

  const content = loadModel($data, 'VMFCom', 'LicenseMapSection');

  useEffect(() => {
    if (businessCards.length === 1) {
      setSelectedLoanOfficer(businessCards[0]);
    }
  }, [businessCards]);

  const licensesForSelectedLoanOfficer = useMemo(() => {
    if (businessCards && businessCards?.length) {
      return (
        selectedLoanOfficer === null
          ? businessCards
          : [businessCards.find(f => f.uid === selectedLoanOfficer.uid)]
      )
        ?.filter(f => f)
        ?.map(card => card.stateLicenses)
        .flat();
    }

    return [];
  }, [selectedLoanOfficer, businessCards]);

  const selectableStates = useMemo(() => {
    const mapStates = v => ({ state: v.state, licenseNumber: v.licenseNumber });
    const filterDuplicates = (p, c) => {
      if (!p.some(s => s.state === c.state)) {
        p.push(c);
      }
      return p;
    };

    if (businessCards && businessCards?.length) {
      if (selectedLoanOfficer) {
        return selectedLoanOfficer.stateLicenses
          .map(mapStates)
          .reduce(filterDuplicates, []);
      } else {
        return businessCards
          .map(card => card.stateLicenses.map(mapStates))
          .flat()
          .reduce(filterDuplicates, []);
      }
    }

    return [];
  }, [businessCards, selectedLoanOfficer]);

  return (
    <InView>
      <Styles
        mapBackgroundColor={getColors(content.mapBackgroundColor).primary}
        sectionHeaderTextColor={
          getColors(content.sectionHeaderTextColor).primary
        }
        pickerPosition={pickerPosition}
        selectedState={selectedState}
      >
        {modalOfficer && (
          <Modal onClose={() => setModalOfficer(null)} fitContent={true}>
            <LOModal
              officer={modalOfficer}
              greeting={content.teamMembers?.modalGreeting}
            />
          </Modal>
        )}
        <h1 className='license-map__header'>
          {selectedLoanOfficer
            ? selectedLoanOfficer.firstName + "'s Licenses"
            : content.mapSectionHeader}
        </h1>

        <div className='license-map__content'>
          <div className='license-map__map-and-picker'>
            <div className='license-map__map-container'>
              <InteractiveMap
                selectableStates={selectableStates.map(v => v.state)}
                selectedState={selectedState}
                onStateClick={setSelectedState}
                stateTooltipData={
                  showTooltips && selectedLoanOfficer
                    ? licensesForSelectedLoanOfficer.map(v => ({
                        state: v.state,
                        text: v.licenseNumber,
                        heading: v.state,
                      }))
                    : []
                }
                mapBackgroundColor={
                  getColors(content.mapBackgroundColor).primary
                }
                selectableStateColor={
                  getColors(content.licensedStateColor).primary
                }
                selectedStateColor={
                  getColors(content.selectedStateColor).primary
                }
                stateBorderColor={getColors(content.stateBorderColor).primary}
              />
            </div>
            <div className='license-map__picker-container'>
              <Picker
                data={selectableStates
                  ?.map(v => ({
                    title: `${v.state}${displayLicenseNumbersInPicker ? `- ${v.licenseNumber}` : ''}`,
                    value: v.state,
                  }))
                  ?.sort((a, b) => (a.title > b.title ? 1 : -1))}
                showAllOption={businessCards && businessCards.length > 1}
                allOptionTitle='All Loan Officers'
                allOptionValue='all'
                value={selectedState}
                onChange={value => {
                  setSelectedState(value);
                }}
              />
            </div>
          </div>
        </div>
      </Styles>
    </InView>
  );
};

export default IndividualLicenseMapSection;
