import styled from 'styled-components';
import { getColors } from 'vmfcom-lib/utils';

const getTextAlign = (alignContent) => {
    switch (alignContent) {
      case "Center Align":
        return 'center';
      case "Right Align":
        return 'right';
      case "Left Align":
      default:
        return 'left';  
    }
};

export default styled.section`
 display: flex;
 height: 100%;
 width: 100%;
 justify-content: center;
 flex-wrap: nowrap;  

 .hero-section {
  display: flex;
  flex-direction: ${props => props.$data.mediaPosition === 'Right Side' ? 'row' : 'row-reverse'};
  width: 100%;
  flex-wrap: nowrap;
  background-color: ${props => getColors(props.$data.heroBackgroundColor).primary || '#FFFFFF'};  

  @media(max-width: ${props => props.theme.breakpoints.tablet}){    
    flex-direction: ${props=> props.$data.mediaPosition === 'Right Side' ? 'column' : 'column-reverse'};     
  }
   
  .hero-content {
    display: inline-block;
    flex-direction: column;
    padding: 0.5rem;
    width: 50%;
    height: 100%;
    align-items: center;
    background-color: ${props => getColors(props.$data.heroContent.textBlockBackgroundColor).primary || '#FFFFFF'};
    text-align: ${props => getTextAlign(props.$data.heroContent.alignText)|| 'center'};

    @media(max-width: ${props => props.theme.breakpoints.desktop}) {
      width: 100%;
    }
    
    h1, h3, p {
      font-weight: 700;
    }

    h1 {
      color: ${props => getColors(props.$data.heroContent.headerTextColor).primary || '#FFFFFF'};
      font-size: 78px;
      line-height: 66px;
      margin-bottom: 2rem;
      letter-spacing: -2px;
      @media(max-width: ${props => props.theme.breakpoints.desktopLarge}) {
        font-size: 51px;
        line-height: 39px;
        letter-spacing: -1px; 
      }
  
      @media(max-width: 798px) {
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 1rem;       
      }
    }

    h3 {
      color: ${props => getColors(props.$data.heroContent.introTextColor).primary || '#FFFFFF'};
      font-size: 33px;
      letter-spacing: -1px;
      margin: 0;
  
       @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
            font-size: 18px;
            line-height: 21px;
            letter-spacing: -0.56px; 
        }
  
        @media(max-width: ${props => props.theme.breakpoints.tablet}) {
            font-size: 15px;
            line-height: 15px;
        }
    }

    p {
      color: ${props => getColors(props.$data.heroContent.disclaimerTextColor).primary || '#FFFFFF'};
      font-size: 15px;
      margin: 15px 0;
      line-height: 18px;
      font-family: "source-sans-pro", sans-serif;
  
      @media(max-width: ${props => props.theme.breakpoints.desktopMedLarge}) {
          font-size: 12px;
          line-height: 10.078px;
       }
    }

    .intro-links{
      margin-top: 32px;
    }
  }

  .hero-image {
    display: flex;
    height: 100%;     
    width: 100%;
    object-fit: cover;      

    img {
      width: 100%;
      height: 100%;
    }   
  
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      width: 50%;         
    }     

    ${props => props.$data.displayTabletMedia === false ? '@media (max-width: 992px){display: none;}' : ''}

    ${props => props.$data.displayMobileMedia === false ? '@media (max-width: 640px){display: none;}' : '@media (max-width: 640px){display: flex;}'}
  }   
 }  
`;