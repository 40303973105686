import React from 'react';

const GenerateQRCode = ({ color = null, size = 22, colorClass = '' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 9.42857H9.42857V0H0V9.42857ZM3.14286 3.14286H6.28571V6.28571H3.14286V3.14286ZM12.5714 0V9.42857H22V0H12.5714ZM18.8571 6.28571H15.7143V3.14286H18.8571V6.28571ZM0 22H9.42857V12.5714H0V22ZM3.14286 15.7143H6.28571V18.8571H3.14286V15.7143ZM20.4286 12.5714H22V18.8571H17.2857V17.2857H15.7143V22H12.5714V12.5714H17.2857V14.1429H20.4286V12.5714ZM20.4286 20.4286H22V22H20.4286V20.4286ZM17.2857 20.4286H18.8571V22H17.2857V20.4286Z'
        fill={color}
        className={colorClass}
      />
    </svg>
  );
};

export default GenerateQRCode;
