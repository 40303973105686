import React from 'react';
import { MultiEntry } from 'vmfcom-lib/react';
import TextSection from '../TextSection';
import CTA from '../CTA';
import InteractiveHomePartnersSection from '../InteractiveHomePartnersSection';
import SharedComponent from '../SharedComponent';
import HeroSection from '../HeroSection/HeroSection';
import LicenseMap from '../LicenseMapSection/LicenseMapSection';
import Disclaimer from '../Disclaimer';
import FloatingText from '../FloatingText';

const components = {
  textSection: {
    component: TextSection,
  },
  cta: {
    component: CTA,
  },
  interactiveHomePartnersSection: {
    component: InteractiveHomePartnersSection,
  },
  sharedComponent: {
    component: SharedComponent,
  },
  heroSectionPromo: {
    component: HeroSection,
  },
  disclaimer: {
    component: Disclaimer,
  },
  floatingText: {
    component: FloatingText,
  },
  licenseMapSection: {
    component: LicenseMap,
  },
};

const MainContent = ({
  entries = [],
  componentProps = {},
  componentClasses = {},
}) => {
  const modifiedComponents = Object.entries(components).reduce(
    (p, [key, config]) => {
      const out = { ...p };
      out[key] = config;
      const props = componentProps?.[key];
      const classes = componentClasses?.[key];
      if (props) out[key].props = props;
      if (classes) out[key].classes = classes;
      return out;
    },
    {}
  );

  if (entries) {
    return (
      <MultiEntry
        space='VMFCom'
        components={modifiedComponents}
        entries={entries}
      />
    );
  }
  return <></>;
};

export default MainContent;
