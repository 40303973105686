import styled from 'styled-components'

const PickerWrapperStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .arrow-btn {
        border: none;
        background: none;
        cursor: pointer;
    }

    .up-arrow {
        display: none;
        @media(max-width: ${props => props.theme.breakpoints.tablet}){
            display: block;
        }
    }

    .down-arrow {   
        display: none;
        @media(max-width: ${props => props.theme.breakpoints.tablet}){
            display: block;
        }
    }
        
    
`

export default PickerWrapperStyles