import 'core-js/features/math/sign';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Calculator from '../../components/Calculator/new';
import Styles from './CalculatorSection.styled';
import Slider from '../../components/shared/slider';
import { PieChart } from 'react-minimal-pie-chart';
import Results from '../../components/shared/CalculatorResults/PieChart';
import theme from '../../styles/theme';
import monthlyPaymentCalculator from '../../util/calculators/monthlyPaymentCalculator';
import useGTM from '../../hooks/useGTM';
import toCurrency from '../../util/toCurrency';
import cleanNumber from '../../util/cleanNumber';
import InView from '../Shared/InView';
import SimpleAnimation from '../../styles/Components/SimpleAnimation';
import ReactMarkdown from 'react-markdown';

const defaultErrors = {
  loanAmount: false,
  interestRate: false,
  currentPayment: false,
  additionalPayment: false,
};

const schema = yup.object().shape({
  loanAmount: yup
    .number()
    .typeError('Home price is required')
    .transform((o, v) => cleanNumber(v))
    .min(1, 'Home price must be no less than $1')
    .max(500000, 'Home price cannot exceed $500,000'),
  downPayment: yup
    .number()
    .typeError('Down payment is required')
    .transform((o, v) => cleanNumber(v))
    .min(1, 'Down payment must be no less than $1')
    .max(495000, 'Down payment must not exceed $495,000'),
  interestRate: yup
    .number()
    .typeError('Interest rate is required')
    .transform((o, v) => cleanNumber(v))
    .min(3.5, 'Interest rate must be no less than 3.5%')
    .max(25, 'Interest rate must not exceed 25%'),
  loanTerm: yup
    .number()
    .typeError('Loan term is required')
    .transform((o, v) => cleanNumber(v))
    .min(1, 'Loan term must be no less than 1 year')
    .max(30, 'Loan term must not exceed 30 years'),
  annualTax: yup
    .number()
    .typeError('Annual property taxes is required')
    .transform((o, v) => cleanNumber(v))
    .min(1, 'Annual property taxes must be no less than $1')
    .max(10000, 'Annual property taxes must not exceed $10,000'),
  annualInsurance: yup
    .number()
    .typeError('Annual property insurance is required')
    .transform((o, v) => cleanNumber(v))
    .min(1, 'Annual property insurance must be no less than $1')
    .max(2000, 'Annual property insurance must not exceed $2,000'),
});

const updateValue =
  (values, setValue, events, setEvents, triggerGTMEvent) =>
  ({ currentTarget }) => {
    const { type, name } = currentTarget;
    const hasDecimal = currentTarget.value.indexOf('.') > -1;
    const [value, decimal = ''] = currentTarget.value
      .replace(/[^0-9.]/g, '')
      .split('.');
    const formattedString = `${value.split(/(?=(?:\d{3})+(?:\.|$))/g).join(',')}${
      hasDecimal ? '.' : ''
    }${decimal.slice(0, 2)}`;

    setValue({
      ...values,
      [name]: formattedString,
    });

    if (events[name][type]) {
      clearTimeout(events[name][type]);
    }

    setEvents({
      ...events,
      [name]: {
        ...events[name],
        [type]: setTimeout(() => {
          setEvents({
            ...events,
            [name]: {
              ...events[name],
              [type]: false,
            },
          });
        }, 500),
      },
    });
  };

const disclaimerPositions = {
  BELOW_PIE_CHART: 'BELOW_PIE_CHART',
  BOTTOM_OF_SECTION: 'BOTTOM_OF_SECTION',
};

const CalculatorSection = ({
  header = '',
  subHeader = '',
  disclaimerPosition = disclaimerPositions.BELOW_PIE_CHART,
  className = '',
  calculatorClassName = 'calculator-section-calculator',
  disclaimer,
  backgroundColor,
  headerTextColor,
}) => {
  const [triggerGTMEvent] = useGTM();

  const [errors, setErrors] = useState(defaultErrors);
  const [values, setValues] = useState({
    loanAmount: '75,000',
    downPayment: '5,000',
    interestRate: '8',
    loanTerm: '25',
    annualTax: '1,000',
    annualInsurance: '300',
  });
  const [results, setResults] = useState({
    error: null,
    monthlyInsurance: null,
    monthlyPrincipalAndInterest: null,
    monthlyTax: null,
    totalMonthlyPayment: null,
  });
  const [events, setEvents] = useState({
    loanAmount: {
      text: false,
      range: false,
    },
    downPayment: {
      text: false,
      range: false,
    },
    interestRate: {
      text: false,
      range: false,
    },
    loanTerm: {
      text: false,
      range: false,
    },
    annualTax: {
      text: false,
      range: false,
    },
    annualInsurance: {
      text: false,
      range: false,
    },
  });

  useEffect(() => {
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        const results = monthlyPaymentCalculator(
          cleanNumber(values.loanTerm),
          cleanNumber(values.interestRate),
          cleanNumber(values.loanAmount) - cleanNumber(values.downPayment),
          cleanNumber(values.annualTax),
          cleanNumber(values.annualInsurance)
        );
        setResults(results);
        setErrors(defaultErrors);
      })
      .catch(({ inner }) => {
        const newErrors = inner.reduce(
          (errors, { path, message }) => ({
            ...errors,
            [path]: message,
          }),
          {}
        );

        setErrors({
          ...defaultErrors,
          ...newErrors,
        });
        setResults({
          error: 'Please fix the errors to see your results',
        });
      });
  }, [values]);

  return (
    <InView>
      <Styles
        className={className}
        hasSubHeader={!!subHeader}
        hasFooter={!!disclaimer}
        backgroundColor={backgroundColor}
        headerTextColor={headerTextColor}
      >
        {header && (
          <SimpleAnimation.h1
            className='calculator-section-header'
            slideDirection='up'
            delay={0.2}
          >
            {header}
          </SimpleAnimation.h1>
        )}
        {subHeader && (
          <SimpleAnimation.p
            className='caption calculator-section-sub-header'
            slideDirection='up'
            delay={0.4}
          >
            {subHeader}
          </SimpleAnimation.p>
        )}
        <SimpleAnimation.div shouldSlide={false} delay={0.6}>
          <Calculator
            className={calculatorClassName}
            fields={
              <>
                <Slider
                  label='Home price'
                  name='loanAmount'
                  min={1}
                  max={500000}
                  value={values.loanAmount}
                  error={errors.loanAmount}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  prefix='$'
                />
                <Slider
                  label='Down payment'
                  name='downPayment'
                  min={1}
                  max={495000}
                  value={values.downPayment}
                  error={errors.downPayment}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  prefix='$'
                />
                <Slider
                  label='Interest rate'
                  name='interestRate'
                  min={3.5}
                  max={25}
                  step={0.25}
                  value={values.interestRate}
                  error={errors.interestRate}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  suffix='%'
                  tooltip='Interest Rate - The percentage cost of the principal borrowed.'
                />
                <Slider
                  label='Loan term'
                  name='loanTerm'
                  min={1}
                  max={30}
                  value={values.loanTerm}
                  error={errors.loanTerm}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  suffix='yrs'
                />
                <Slider
                  label='Annual property taxes'
                  name='annualTax'
                  min={1}
                  max={10000}
                  value={values.annualTax}
                  error={errors.annualTax}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  prefix='$'
                />
                <Slider
                  label='Annual property insurance'
                  name='annualInsurance'
                  min={1}
                  max={2000}
                  value={values.annualInsurance}
                  error={errors.annualInsurance}
                  onChange={updateValue(
                    values,
                    setValues,
                    events,
                    setEvents,
                    triggerGTMEvent
                  )}
                  prefix='$'
                />
              </>
            }
            results={
              <Results
                title={
                  !results.error ? (
                    <h2 className='type-h4'>
                      Estimated monthly payment breakdown
                    </h2>
                  ) : (
                    <h2 className='type-h4'>{results.error}</h2>
                  )
                }
                label={
                  !results.error ? (
                    <>
                      <h3>{toCurrency(results.totalMonthlyPayment)}</h3>
                      <h4>per month</h4>
                    </>
                  ) : null
                }
                pieChart={
                  !results.error ? (
                    <PieChart
                      data={[
                        {
                          title: 'Principal and Interest',
                          value: results.monthlyPrincipalAndInterest
                            ? results.monthlyPrincipalAndInterest /
                              results.totalMonthlyPayment
                            : 0,
                          color: theme.colors.vmfGreen2,
                        },
                        {
                          title: 'Home Insurance',
                          value: results.monthlyInsurance
                            ? results.monthlyInsurance /
                              results.totalMonthlyPayment
                            : 0,
                          color: theme.colors.babyBlue,
                        },
                        {
                          title: 'Property Taxes',
                          value: results.monthlyTax
                            ? results.monthlyTax / results.totalMonthlyPayment
                            : 0,
                          color: theme.colors.yellow,
                        },
                      ]}
                      lineWidth={25}
                    />
                  ) : null
                }
                legend={
                  !results.error
                    ? [
                        {
                          title: 'Principal and Interest',
                          color: theme.colors.vmfGreen2,
                          value: toCurrency(
                            results.monthlyPrincipalAndInterest
                          ),
                        },
                        {
                          title: 'Home Insurance',
                          color: theme.colors.babyBlue,
                          value: toCurrency(results.monthlyInsurance),
                        },
                        {
                          title: 'Property Taxes',
                          color: theme.colors.yellow,
                          value: toCurrency(results.monthlyTax),
                        },
                      ]
                    : []
                }
              />
            }
            disclaimer={
              disclaimer &&
              disclaimerPosition === disclaimerPositions.BELOW_PIE_CHART ? (
                <ReactMarkdown
                  className='calculator-section-footer'
                  source={disclaimer}
                />
              ) : null
            }
          />
        </SimpleAnimation.div>
        {disclaimer &&
        disclaimerPosition === disclaimerPositions.BOTTOM_OF_SECTION ? (
          <SimpleAnimation.footer
            className='type-xs calculator-section-footer'
            delay={0.8}
            slideDirection='up'
          >
            <ReactMarkdown source={disclaimer} />
          </SimpleAnimation.footer>
        ) : null}
      </Styles>
    </InView>
  );
};

CalculatorSection.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  disclaimerPosition: PropTypes.oneOf(Object.values(disclaimerPositions)),
  className: PropTypes.string,
  calculatorClassName: PropTypes.string,
  disclaimer: PropTypes.any,
};

export default CalculatorSection;
