import React, { useEffect, useRef, useState, useMemo } from 'react';
import Caret from '../Shared/Icons/Caret';
import Styles from './Tooltip.styles';
import { useAppContext } from '../../contexts/AppContext';
import theme from '../../styles/theme';

const _config = {
  caret: {
    size: 19.188,
    offsetY: 2,
  },
  animation: {
    durationMs: 200,
  },
};

/**
 * See return statement for available nodes and add if needed
 *
 * Tooltip Props
 *  - backgroundColor: [string]
 *    Sets background color for tooltip and caret.
 *
 *  - boxShadow: [boolean]
 *    If true, casts a box shadow from the tooltip content
 *
 *  - headingColor: [string]
 *    Sets text color for tooltip heading.
 *
 *  - textColor: [string]
 *    Sets text color for tooltip.
 *
 *  - xPosition: ['auto', 'left', 'right', 'center']
 *    Sets position of tooltip horizontally, auto will auto-decide
 *    based on window size and the parent elements position in the
 *    DOM.
 *
 *  - yPosition ['auto', 'top', 'bottom']
 *    Sets position on tooltip vertically, auto will auto-decide
 *    based on window size and the parent elements position in the
 *    DOM
 *
 *  - xOffset [number | string]
 *    Accepts either a number (in pixels) or a string (percentage)
 *    and offsets the caret by that amount. (Does not apply to
 *    center positioned xPosition). Examples: 10 would offset the
 *    caret 10px to the right of it's parents left bounds. 50%
 *    would center the caret by offsetting 50% of the parent's
 *    width
 *
 *  - headingText [string]
 *    If present, will render a heading above the tooltipText.
 *
 *  - tooltipText [string]
 *    Text inside the tooltip
 *
 *  - tooltipDelay [number (ms)]
 *    Number of milliseconds(ms) before tooltip displays on hover or
 *    active. This delay does not get applied on click if showOnClick
 *    is set to true
 *
 *  - showOnClick [boolean]
 *    Should the tooltip appear when the parent element is clicked
 *
 *  - showOnFocus [boolean]
 *    Should the tooltip appear when the parent element is focused
 *
 *  - showOnHover [boolean]
 *    Should the tooltip appear when the parent element is hovered
 *
 *  - tooltipDuration [number(ms)]
 *    Only applies if showOnClick is true. Amount of milliseconds(ms)
 *    the tool tip is shown before going away
 *
 *
 */

const xPositions = {
  auto: 'auto',
  left: 'left',
  right: 'right',
  center: 'center',
};

const yPositions = {
  auto: 'auto',
  top: 'top',
  bottom: 'bottom',
};

const getTooltipCoordinates = (
  customBoxCoords,
  xPosition,
  yPosition,
  tooltipRect,
  tooltipWidth,
  parentRect,
  parentWidth,
  parentHeight,
  windowWidth,
  windowHeight,
  caretSize,
  offset
) => {
  const coordinates = {
    tooltip: {
      left: 0,
      top: 0,
    },
    caret: {
      top: 0,
    },
    showTopCaret: false,
    showBottomCaret: true,
  };

  let [baseX, baseY] = [0, 0];

  if (customBoxCoords) {
    baseX = customBoxCoords.box[0][0];
    baseY = customBoxCoords.box[0][1];
    parentRect = customBoxCoords.boxRelativeToWindow;
    parentWidth = customBoxCoords.width;
    parentHeight = customBoxCoords.height;
  }

  if (
    xPosition === xPositions.left ||
    (xPosition === xPositions.auto && parentRect.left > windowWidth / 2)
  ) {
    coordinates.tooltip.left =
      0 - tooltipWidth + caretSize / 2 + offset + baseX;
    coordinates.caret.left = tooltipRect.width - caretSize;
  } else if (
    xPosition === xPositions.right ||
    (xPosition === xPositions.auto && parentRect.left < windowWidth / 2)
  ) {
    coordinates.tooltip.left = 0 - caretSize / 2 + offset + baseX;
    coordinates.caret.left = 0;
  } else {
    coordinates.tooltip.left = 0 - tooltipWidth / 2 + parentWidth / 2 + baseX;
    coordinates.caret.left = 0 + tooltipWidth / 2 - caretSize / 2;
  }

  if (
    yPosition === yPositions.top ||
    (yPosition === yPositions.auto && parentRect.top > windowHeight / 2)
  ) {
    if (customBoxCoords) {
      coordinates.tooltip.top =
        baseY - (tooltipRect.height - caretSize) - caretSize;
    } else {
      coordinates.tooltip.top =
        baseY - tooltipRect.height - (caretSize + _config.caret.offsetY * 1);
    }
    coordinates.caret.top = _config.caret.offsetY * -1;
    coordinates.showTopCaret = false;
    coordinates.showBottomCaret = true;
  } else {
    if (customBoxCoords) {
      coordinates.tooltip.top = baseY + (parentHeight - caretSize) + caretSize;
    } else {
      coordinates.tooltip.top = caretSize;
    }
    coordinates.caret.top = _config.caret.offsetY;
    coordinates.showTopCaret = true;
    coordinates.showBottomCaret = false;
  }

  return coordinates;
};

// const handleScroll = (setLastScroll) => setLastScroll(window.scrollY)

export const Tooltip = ({
  backgroundColor = '#777B7E',
  boxShadow = false,
  textColor = '#FFFFFF',
  headingColor = theme.colors.vmfBlue,
  textAlign = '',
  xPosition = xPositions.auto,
  yPosition = yPositions.auto,
  xOffset = '50%',
  headingText = '',
  tooltipText = '',
  tooltipDelay = 0,
  showOnClick = false,
  showOnFocus = false,
  showOnHover = true,
  tooltipDuration = 0,
  customBoxCoords = null,
  parent,
}) => {
  const [{ windowSize }] = useAppContext();
  const tooltipRef = useRef(null);

  // Timers
  const delayTimer = useRef(null);
  const animationTimer = useRef(null);
  const tooltipDurationTimer = useRef(null);

  // Show state
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTopCaret, setShowTopCaret] = useState(true);
  const [showBottomCaret, setShowBottomCaret] = useState(false);
  const [animatingOut, setAnimatingOut] = useState(false);

  // Position State
  const [caretPosition, setCaretPosition] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({});
  // const [lastScroll, setLastScroll] = useState(0)

  const clearTimers = () => {
    if (delayTimer.current) {
      clearTimeout(delayTimer.current);
      delayTimer.current = null;
    }

    if (animationTimer.current) {
      clearTimeout(animationTimer.current);
      animationTimer.current = null;
    }

    if (tooltipDurationTimer.current) {
      clearTimeout(tooltipDurationTimer.current);
      tooltipDurationTimer.current = null;
    }
  };

  const displayTooltip = duration => {
    clearTimers();

    const display = () => {
      setAnimatingOut(false);
      setShowTooltip(true);

      if (duration && duration > 0) {
        tooltipDurationTimer.current = setTimeout(() => {
          clearTimers();
          hideTooltip();
        }, duration);
      }
    };

    if (tooltipDelay && tooltipDelay > 0) {
      delayTimer.current = setTimeout(() => {
        display();
      }, tooltipDelay);
    } else {
      display();
    }
  };

  const hideTooltip = duration => {
    clearTimers();

    const hide = () => {
      setAnimatingOut(true);
      animationTimer.current = setTimeout(() => {
        clearTimers();
        setShowTooltip(false);
        setAnimatingOut(false);
      }, _config.animation.durationMs);
    };

    if (duration && duration > 0) {
      tooltipDurationTimer.current = setTimeout(() => {
        hide();
      }, tooltipDuration);
    } else {
      hide();
    }
  };

  // useEffect(() => {
  //   if (showTooltip) {
  //     window.removeEventListener('scroll', handleScroll(setLastScroll))
  //     window.addEventListener('scroll', handleScroll(setLastScroll))
  //   } else {
  //     window.removeEventListener('scroll', handleScroll(setLastScroll))
  //   }
  // }, [showTooltip])

  // Set tooltip and caret position
  useEffect(() => {
    if (showTooltip && (parent?.current || parent !== null)) {
      const offset =
        typeof xOffset === 'string'
          ? xOffset.includes('%')
            ? (parent?.current?.offsetWidth ||
                parent.offsetWidth ||
                parent?.getBoundingClientRect()?.width) *
              (parseInt(xOffset.replace('%', '')) / 100)
            : xOffset.replace(/[^0-9]/gim, '')
          : typeof xOffset === 'number'
            ? xOffset
            : 0;

      const newCoordinates = getTooltipCoordinates(
        customBoxCoords,
        xPosition,
        yPosition,
        tooltipRef.current.getBoundingClientRect(),
        tooltipRef.current.offsetWidth,
        parent?.current?.getBoundingClientRect() ||
          parent?.getBoundingClientRect(),
        parent?.current?.offsetWidth || parent?.offsetWidth,
        parent?.current?.offsetHeight || parent?.offsetHeight,
        window.innerWidth,
        window.innerHeight,
        _config.caret.size,
        offset
      );

      setTooltipPosition(newCoordinates.tooltip);
      setCaretPosition(newCoordinates.caret);
      setShowTopCaret(newCoordinates.showTopCaret);
      setShowBottomCaret(newCoordinates.showBottomCaret);
    }
  }, [parent, showTooltip, windowSize]);

  useEffect(() => {
    const handleClick = e => {
      if (showOnClick) {
        displayTooltip(tooltipDuration);
      }
    };

    const handleFocus = e => {
      if (showOnFocus) {
        displayTooltip();
      }
    };

    const handleBlur = e => {
      if (showOnFocus || (showOnClick && !tooltipDuration)) {
        hideTooltip(tooltipDuration);
      }
    };

    const handleMouseEnter = e => {
      if (showOnHover) {
        if (typeof window !== 'undefined' && window) {
          if (
            window.innerWidth >=
            +theme.breakpoints.tablet.replace(/[^0-9]/gim, '')
          ) {
            displayTooltip();
          }
        } else {
          displayTooltip();
        }
      }
    };

    const handleMouseLeave = e => {
      if (showOnHover) {
        hideTooltip(tooltipDuration);
      }
    };

    if (parent?.current || parent !== null) {
      if (showOnClick || showOnFocus) {
        if (parent?.current) {
          parent.current.addEventListener('blur', handleBlur);
        } else {
          parent?.addEventListener('blur', handleBlur);
        }
      }

      if (showOnClick) {
        if (parent?.current) {
          parent.current.addEventListener('click', handleClick);
        } else {
          parent?.addEventListener('click', handleClick);
        }
      }

      if (showOnFocus) {
        if (parent?.current) {
          parent.current.addEventListener('focus', handleFocus);
        } else {
          parent?.addEventListener('focus', handleFocus);
        }
      }

      if (showOnHover) {
        if (parent?.current) {
          parent.current.addEventListener('mouseenter', handleMouseEnter);
          parent.current.addEventListener('mouseleave', handleMouseLeave);
        } else {
          parent?.addEventListener('mouseenter', handleMouseEnter);
          parent?.addEventListener('mouseleave', handleMouseLeave);
        }
      }
    }

    return () => {
      const parentElement = parent.current;
      parentElement?.removeEventListener('click', handleClick);
      parentElement?.removeEventListener('focus', handleFocus);
      parentElement?.removeEventListener('blur', handleBlur);
      parentElement?.removeEventListener('mouseenter', handleMouseEnter);
      parentElement?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [parent]);

  if (showTooltip) {
    return (
      <Styles
        ref={tooltipRef}
        backgroundColor={backgroundColor}
        boxShadow={boxShadow}
        textColor={textColor}
        textAlign={textAlign}
        headingColor={headingColor}
        animationDuration={_config.animation.durationMs}
        className={`tooltip tooltip${animatingOut ? '--out' : '--in'}`}
        style={{ ...tooltipPosition }}
      >
        {showTopCaret && (
          <Caret
            style={{
              position: 'relative',
              width: _config.caret.size,
              ...caretPosition,
            }}
            color={backgroundColor}
            className='tooltip__caret tooltip__caret--top'
          />
        )}
        <div className='tooltip__content'>
          {headingText && (
            <p className='tooltip__content__heading'>{headingText}</p>
          )}
          {tooltipText}
        </div>
        {showBottomCaret && (
          <Caret
            style={{
              position: 'relative',
              width: _config.caret.size,
              ...caretPosition,
            }}
            color={backgroundColor}
            className='tooltip__caret tooltip__caret--bottom'
          />
        )}
      </Styles>
    );
  }

  return <></>;
};

const createTooltip = node => {
  const Parent = React.forwardRef((props, ref) =>
    React.createElement(node, {
      ...props,
      ref,
      style: { position: 'relative', ...(props?.style || {}) },
    })
  );

  return ({
    backgroundColor = '#777B7E',
    boxShadow = false,
    textColor = '#FFFFFF',
    headingColor = theme.colors.vmfBlue,
    textAlign = '',
    xPosition = xPositions.auto,
    yPosition = yPositions.auto,
    xOffset = '50%',
    headingText = '',
    tooltipText = '',
    tooltipDelay = 0,
    showOnClick = false,
    showOnFocus = false,
    showOnHover = true,
    tooltipDuration = 0,
    children,
    ...parentProps
  }) => {
    const ref = useRef(null);

    return (
      <Parent {...parentProps} ref={ref}>
        <Tooltip
          backgroundColor={backgroundColor}
          boxShadow={boxShadow}
          headingColor={headingColor}
          textColor={textColor}
          xPosition={xPosition}
          yPosition={yPosition}
          xOffset={xOffset}
          headingText={headingText}
          tooltipText={tooltipText}
          tooltipDelay={tooltipDelay}
          showOnClick={showOnClick}
          showOnFocus={showOnFocus}
          showOnHover={showOnHover}
          tooltipDuration={tooltipDuration}
          textAlign={textAlign}
          parent={ref}
        />
        {children}
      </Parent>
    );
  };
};

const getPathLocation = (elem, toElement) => {
  const bBox = elem.getBoundingClientRect();
  const { x, y } = toElement.getBoundingClientRect();

  const topLeft = [bBox.x - x, bBox.y - y];
  const topRight = [bBox.x + bBox.width - x, bBox.y - y];
  const bottomLeft = [bBox.x - x, bBox.y + bBox.height - y];
  const bottomRight = [bBox.x + bBox.width - x, bBox.y + bBox.height - y];

  return {
    box: [topLeft, topRight, bottomLeft, bottomRight],
    center: [topLeft[0] + bBox.width / 2, topLeft[1] + bBox.height / 2],
    width: bBox.width,
    height: bBox.height,
    boxRelativeToWindow: bBox,
  };
};

const TooltipProvider = ({ children, config = {}, ...props }) => {
  const ref = useRef(null);

  const findTooltipElements = (node, output) => {
    if (!!node?.dataset?.tooltiptext) {
      output.push(node);
    }

    if (node?.children?.length) {
      [...node.children].forEach(child => findTooltipElements(child, output));
    }
  };

  const tooltips = useMemo(() => {
    const output = [];
    findTooltipElements(ref.current, output);
    return output.map(elem => {
      const pathCoords = getPathLocation(elem, ref.current);

      return (
        <Tooltip
          customBoxCoords={pathCoords}
          tooltipText={elem.dataset.tooltiptext}
          headingText={elem.dataset.tooltipheading}
          parent={elem}
          {...config}
        >
          {elem}
        </Tooltip>
      );
    });
  }, [children, ref.current]);

  return (
    <div className='ttp' ref={ref} {...props} style={{ position: 'relative' }}>
      {tooltips}
      {children}
    </div>
  );
};

export default {
  provider: TooltipProvider,
  div: createTooltip('div'),
  button: createTooltip('button'),
  a: createTooltip('a'),
  path: createTooltip('path'),
};
