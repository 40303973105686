import React from 'react';
import theme from '../../../styles/theme';

const MailWireframe = ({
  color = null,
  size = 28,
  respect = 'width',
  colorClass = '',
  ...props
}) => {
  return (
    <svg
      {...props}
      width={respect === 'width' ? size : size * 1.3}
      height={respect === 'width' ? size * 0.75 : size}
      viewBox='0 0 28 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.375 0.867188H2.625C1.17523 0.867188 0 1.99049 0 3.3762V18.4303C0 19.816 1.17523 20.9393 2.625 20.9393H25.375C26.8248 20.9393 28 19.816 28 18.4303V3.3762C28 1.99049 26.8248 0.867188 25.375 0.867188ZM25.375 3.3762V5.50912C24.1488 6.46353 22.1939 7.94761 18.0148 11.0755C17.0938 11.7679 15.2694 13.4314 14 13.412C12.7308 13.4316 10.9058 11.7676 9.98523 11.0755C5.80672 7.94808 3.85137 6.46369 2.625 5.50912V3.3762H25.375ZM2.625 18.4303V8.72865C3.87811 9.68264 5.65518 11.0214 8.3638 13.0486C9.5591 13.948 11.6524 15.9333 14 15.9212C16.3361 15.9333 18.4028 13.9768 19.6357 13.0491C22.3443 11.0218 24.1218 9.68275 25.375 8.7287V18.4303H2.625Z'
        fill={color}
        className={colorClass}
      />
    </svg>
  );
};

export default MailWireframe;
