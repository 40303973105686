import styled from 'styled-components';

const MapStyles = styled.div`
  path {
    stroke: ${props =>
      props.stateBorderColor
        ? props.stateBorderColor
        : props.theme.colors.black};
  }

  svg {
    width: 100%;
  }

  .michigan:hover {
    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      .selectable {
        fill: ${props =>
          props.selectedStateColor
            ? props.selectedStateColor
            : props.theme.colors.vmfBlue};
      }
    }
  }

  .selectable {
    fill: ${props =>
      props.selectableStateColor
        ? props.selectableStateColor
        : props.theme.colors.babyBlue};

    &:hover {
      cursor: pointer;

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        fill: ${props =>
          props.selectedStateColor
            ? props.selectedStateColor
            : props.theme.colors.vmfBlue};
      }
    }
  }

  .selected {
    fill: ${props =>
      props.selectedStateColor
        ? props.selectedStateColor
        : props.theme.colors.vmfBlue};
  }
`;
export default MapStyles;
