import styled from 'styled-components';

export default styled.div`
  padding: 1.75rem 1.75rem 1.25rem;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.low};
  box-sizing: border-box;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 1.75rem 1.25rem 1.25rem;
  }

  a {
    text-transform: capitalize;
    cursor: pointer;
  }

  .rows {
    margin: 1.15625rem 0 1.625rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: ${props => props.theme.colors.grey1};
      font-size: ${props => props.theme.type.caption};
      line-height: ${props => props.theme.lineHeights.caption.default};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    }

    svg {
      width: 0.9375rem;
      color: ${props => props.theme.colors.claytonBlue};
      margin-right: 0.875rem;
    }
  }

  h6 {
    margin: 0;
  }

  .dealer-card-distance {
    font-weight: 500;
  }
`;
