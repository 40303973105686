import styled from 'styled-components';
import { pickerPositions } from './index';

export const LOModalStyles = styled.div`
  box-sizing: border-box;
  /* width: max-content; */
  max-width: 40rem;
  padding: 2rem;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'futura-pt';
  }

  a {
    font-family: 'nunito';
    color: ${props => props.theme.colors.darkBlue};
  }

  & > h2 {
    color: ${props => props.theme.colors.darkBlue};
    font-weight: 700;
    font-size: 1.5625rem;
    margin-bottom: 1rem;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.375rem;
    }
    
  }

  & > a {
    font-size: 1rem;
  }

  .lo-modal__card {
    margin-top: 1rem;
    border: 1px solid ${props => props.theme.colors.babyBlue};
    padding: 2rem;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      flex-direction: column;
    }

    &__image {
      overflow: hidden;
      border-radius: 100%;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
      width: 9.33375rem;
      height: 9.33375rem;
      flex: none;
      
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__details {
      padding-left: 1.8rem;

      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        padding-left: 0;
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      @media (min-width: 390px) {
        width: max-content;
      }

      * {
        margin-bottom: .2rem;
      }

      h3 {
        color: ${props => props.theme.colors.vmfBlue};
        font-size: 1.25rem;
        margin-bottom: .5rem;
      }

      p, a {
        color: ${props => props.theme.colors.bedtime};
        font-family: 'nunito';
        font-weight: 400;
        display: block;
        font-size: 0.875rem;
        line-height: 22px;
      }
    }

    h3, p, a {
      @media (max-width: ${props => props.theme.breakpoints.tablet}) {
        text-align: center;
      }
    }
    
  }
  
`;

const LicenseMapStyles = styled.div`
    background: ${props => props.mapBackgroundColor};
    padding: 1rem;
    padding-bottom: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    .button {
      &--empty {
        padding: 0;
        background: none;
        cursor: pointer;
        border: none;
        font-family: inherit;
        font-size: inherit;
      }
    }

    .license-map {

      &__header {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: ${props => props.theme.colors.darkBlue};
      }

      &__view-all-button {
        font-size: 1rem;
        color: ${props => props.theme.colors.darkBlue};
        visibility: ${props => props.selectedState ? 'visible' : 'hidden'};
        margin-bottom: .75rem;

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          display: none;
        }
        
      }

      &__content {
        display: ${props => props.mapOnly ? 'block' : 'flex'};
        margin: ${props => props.mapOnly ? '0 auto' : ''};
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 62rem;

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          flex-direction: column-reverse;
          align-items: center;
          justify-content: flex-start;
        }
        
      }

      &__lo-list {
        background-color: ${props => props.licenseListBackgroundColor};
        min-width: 15rem;
        max-width: 21rem;
        width: 100%;
        border-radius: .3rem;

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          margin-top: 2rem;
        }

        h3 {
          color: ${props => props.theme.colors.vmfBlue};
          font-size: 1.25rem;
          padding: 0.9rem 2.25rem 0.7rem;
          margin: 0;
        }

        hr {
          border: 1px solid ${props => props.mapBackgroundColor};
          margin: 0;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 1rem 2.25rem;
          max-height: 320px;
          overflow-y: auto;

          li {
            font-size: 1.125rem;
            color: ${props => props.theme.colors.black};
            padding: 0;
            padding-top: 0.2rem;
          }
          
        }
        
      }

      &__map-and-picker {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        
        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          align-items: center;
          justify-content: flex-start;
          flex-direction: ${props => props.pickerPosition === pickerPositions.top ? 'column-reverse' : 'column'};
        }
      }

      &__map-container {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
      }

      &__picker-container {
        width: auto;

        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          width: 100%;
        }
        
      }
      
    }
    
`;
export default LicenseMapStyles;