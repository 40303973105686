import styled from 'styled-components'

export default styled.div`
    .mortgage-calc-text {
        background-color: ${props => props.theme.colors.grey6};
        padding: 2rem;
        p {
            font-size: .875rem;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .business-card-cta {
        h1, h2, h3, h4, h5, h6, p {
            margin: 0;
            padding: 0;
        }

        .cta__links {
            margin: 0;
            padding: 0;
        }
    }
`