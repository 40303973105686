import styled from 'styled-components';

export default styled.section`
  padding: 7.6875rem ${props => props.theme.padding.inner};
  display: flex;
  justify-content: center;

  .primary {
    display: inline-block;

    h4,
    h1 {
      margin: 0;
      text-align: left;
    }

    a {
      display: inline-block;
      margin-top: 1.5rem;
      font-size: ${props => props.theme.type.paragraph.default};
      line-height: ${props => props.theme.lineHeights.paragraph.default};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
      font-weight: 600;
    }

    svg {
      bottom: 0.7em;
    }
  }

  ${props => props.theme.animations.houseRock};
  ${props => props.theme.animations.fourOhFour.backgroundLoop};
  ${props => props.theme.animations.fourOhFour.cloudLoop};
`;
