import styled from 'styled-components';

const FloatingTextStyles = styled.section`
        background: ${props => props.backgroundColor ? props.backgroundColor : props.theme.colors.darkBlue};
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1.75rem;

        .${props => props.primaryClass} {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            align-items: ${props => props.$data?.textPosition === "Center" ? "center" : props.$data?.textPosition === "Right Side" ? "flex-end" : "flex-start"};
            text-align: ${props => props.$data?.alignText === "Center Align" ? "center" : props.$data?.alignText === "Right Align" ? "right" : "left"};
            color: ${props => props.textColor};
            @media (min-width: ${props => props.theme.breakpoints.desktop}) {
                margin: 0px auto;
                max-width: 1228px;
            }
            
            &__content { 
                display: flex;
                flex-direction: ${props => props.$data.contentDisplayOrder === "Link then Text" ? "column-reverse" : "column"};
                &__text {
                    p {
                        color: ${props => props.textColor};
                    }
                }

                &__links {
                    a, a:hover, a:active {
                        border: none;
                        text-decoration: none;
                        color: ${props => props.theme.colors.landDownBlue};
                        font-weight: 700;
                    }
                }
            }
        }
`;

export default FloatingTextStyles;