import React from 'react';

const CaretDown = ({ color = '#000000', ...props }) => {
  return (
    <svg width='25px' height='25px' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z'
        fill={color}
      />
    </svg>
  );
};

export default CaretDown;
