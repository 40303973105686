import React, { useMemo, useState } from 'react';
import Styles from './Picker.styled';
import Wrapper from './PickerWrapper.styled';
import CaretUp from '../Shared/Icons/CaretUp';
import CaretDown from '../Shared/Icons/CaretDown';

const Picker = ({
  data = [],
  showAllOption = false,
  allOptionTitle = 'View All',
  allOptionValue = 'all',
  itemsToDisplay = 4,
  value = null,
  onChange = () => {},
  ...props
}) => {
  // Ref Calculations
  const actualData = useMemo(() => {
    return [
      ...(showAllOption
        ? [{ title: allOptionTitle, value: allOptionValue }]
        : []),
      ...data,
    ];
  }, [data]);

  const showArrows = useMemo(() => {
    const idx = actualData?.findIndex(f => f.value === value);

    if (idx === -1) return { top: false, bottom: false };

    return {
      top: idx > 0,
      bottom: idx < actualData.length - 1,
    };
  }, [value, actualData]);

  const snapValue = useMemo(() => {
    const found = actualData.findIndex(f => f.value === value);
    if (found !== -1) {
      return found;
    }

    return 0;
  }, [value]);

  const onSnap = ({ target, data, childIndex }) => {
    onChange(data.value);
  };

  const goUp = () => {
    if (actualData?.length) {
      const idx = actualData.findIndex(f => f.value === value);
      if (idx > 0) {
        onChange(actualData[idx - 1].value);
      }
    }
  };

  const goDown = () => {
    if (actualData?.length) {
      const idx = actualData?.findIndex(f => f.value === value);
      if (idx < actualData.length - 1) {
        onChange(actualData[idx + 1].value);
      }
    }
  };

  return (
    <Wrapper>
      <button
        onClick={goUp}
        className='arrow-btn up-arrow'
        disabled={!showArrows.top}
        style={{ visibility: showArrows.top ? 'visible' : 'hidden' }}
      >
        <CaretUp />
      </button>
      <Styles
        onSnap={onSnap}
        snapValue={snapValue}
        displayChildrenMax={itemsToDisplay}
      >
        {actualData.map(({ title, value: val }, index) => (
          <div
            className='picker-item'
            key={'picker-item-' + index}
            data-value={val}
          >
            {title}
          </div>
        ))}
      </Styles>
      <button
        onClick={goDown}
        className='arrow-btn down-arrow'
        disabled={!showArrows.bottom}
        style={{ visibility: showArrows.bottom ? 'visible' : 'hidden' }}
      >
        <CaretDown />
      </button>
    </Wrapper>
  );
};

export default Picker;
