import React from 'react';
import theme from '../../../styles/theme';

const Copy = ({ color = null, colorClass = '', size = 23, ...props }) => {
  return (
    <svg
      width={size * 0.913}
      height={size}
      viewBox='0 0 21 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20.341 2.9544L17.909 0.629896C17.4871 0.226583 16.9148 2.98248e-06 16.318 0L8.25 0C7.00734 0 6 0.962832 6 2.15058V4.30116H2.25C1.00734 4.30116 0 5.26399 0 6.45174V20.7889C0 21.9767 1.00734 22.9395 2.25 22.9395H12.75C13.9927 22.9395 15 21.9767 15 20.7889V18.6384H18.75C19.9927 18.6384 21 17.6755 21 16.4878V4.47509C21 3.90472 20.7629 3.35771 20.341 2.9544ZM12.4688 20.7889H2.53125C2.45666 20.7889 2.38512 20.7606 2.33238 20.7102C2.27963 20.6598 2.25 20.5914 2.25 20.5201V6.72056C2.25 6.64927 2.27963 6.58089 2.33238 6.53048C2.38512 6.48006 2.45666 6.45174 2.53125 6.45174H6V16.4878C6 17.6755 7.00734 18.6384 8.25 18.6384H12.75V20.5201C12.75 20.5914 12.7204 20.6598 12.6676 20.7102C12.6149 20.7606 12.5433 20.7889 12.4688 20.7889ZM18.4688 16.4878H8.53125C8.45666 16.4878 8.38512 16.4595 8.33238 16.409C8.27963 16.3586 8.25 16.2903 8.25 16.219V2.4194C8.25 2.34811 8.27963 2.27973 8.33238 2.22932C8.38512 2.1789 8.45666 2.15058 8.53125 2.15058H13.5V6.09331C13.5 6.68718 14.0037 7.1686 14.625 7.1686H18.75V16.219C18.75 16.2903 18.7204 16.3586 18.6676 16.409C18.6149 16.4595 18.5433 16.4878 18.4688 16.4878ZM18.75 5.01802H15.75V2.15058H16.2015C16.2761 2.15058 16.3476 2.1789 16.4004 2.2293L18.6676 4.39637C18.6938 4.42133 18.7145 4.45097 18.7286 4.48359C18.7427 4.51621 18.75 4.55117 18.75 4.58647V5.01802Z'
        fill={color}
        className={colorClass}
      />
    </svg>
  );
};

export default Copy;
