import React from 'react';
import FourOhFourStyles from './FourOhFour.styled';
import Link from '../Shared/Link';

const defaultLink = {
  to: '/',
  title: 'Back to homepage',
};

const FourOhFour = ({
  status = 404,
  error = 'Page not found.',
  link = defaultLink,
}) => (
  <FourOhFourStyles className='block-four_oh_four'>
    <div className='primary'>
      {status && <h4>{status}</h4>}
      <h1>{error}</h1>
      {link && link.to && link.title && <Link to={link.to}>{link.title}</Link>}
    </div>
  </FourOhFourStyles>
);

export default FourOhFour;
