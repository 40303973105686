export const cssColors = {
  black: '#000000',
  gray: '#808080',
  white: '#FFFFFF',
  fuchsia: '#FF00FF',
  yellow: '#FFFF00',
  blue: '#0000FF',
  aqua: '#00FFFF',
  aliceblue: '#F0F8FF',
  antiquewhite: '#FAEBD7',
  aquamarine: '#7FFFD4',
  azure: '#F0FFFF',
  beige: '#F5F5DC',
  bisque: '#FFE4C4',
  blanchedalmond: '#FFEBCD',
  blueviolet: '#8A2BE2',
  brown: '#A52A2A',
  burlywood: '#DEB887',
  cadetblue: '#5F9EA0',
  chartreuse: '#7FFF00',
  chocolate: '#D2691E',
  coral: '#FF7F50',
  cornflowerblue: '#6495ED',
  cornsilk: '#FFF8DC',
  crimson: '#DC143C',
  cyan: '#00FFFF',
  darkblue: '#00008B',
  darkcyan: '#008B8B',
  darkgoldenrod: '#B8860B',
  darkgray: '#A9A9A9',
  darkgreen: '#006400',
  darkgrey: '#A9A9A9',
  darkkhaki: '#BDB76B',
  darkmagenta: '#8B008B',
  darkolivegreen: '#556B2F',
  darkorange: '#FF8C00',
  darkorchid: '#9932CC',
  darkred: '#8B0000',
  darksalmon: '#E9967A',
  darkseagreen: '#8FBC8F',
  darkslateblue: '#483D8B',
  darkslategray: '#2F4F4F',
  darkslategrey: '#2F4F4F',
  darkturquoise: '#00CED1',
  darkviolet: '#9400D3',
  deeppink: '#FF1493',
  deepskyblue: '#00BFFF',
  dimgray: '#696969',
  dimgrey: '#696969',
  dodgerblue: '#1E90FF',
  firebrick: '#B22222',
  floralwhite: '#FFFAF0',
  forestgreen: '#228B22',
  gainsboro: '#DCDCDC',
  ghostwhite: '#F8F8FF',
  gold: '#FFD700',
  goldenrod: '#DAA520',
  green: '#008000',
  greenyellow: '#ADFF2F',
  grey: '#808080',
  honeydew: '#F0FFF0',
  hotpink: '#FF69B4',
  indianred: '#CD5C5C',
  indigo: '#4B0082',
  ivory: '#FFFFF0',
  khaki: '#F0E68C',
  lavender: '#E6E6FA',
  lavenderblush: '#FFF0F5',
  lawngreen: '#7CFC00',
  lemonchiffon: '#FFFACD',
  lightblue: '#ADD8E6',
  lightcoral: '#F08080',
  lightcyan: '#E0FFFF',
  lightgoldenrodyellow: '#FAFAD2',
  lightgray: '#D3D3D3',
  lightgreen: '#90EE90',
  lightgrey: '#D3D3D3',
  lightpink: '#FFB6C1',
  lightsalmon: '#FFA07A',
  lightseagreen: '#20B2AA',
  lightskyblue: '#87CEFA',
  lightslategray: '#778899',
  lightslategrey: '#778899',
  lightsteelblue: '#B0C4DE',
  lightyellow: '#FFFFE0',
  lime: '#00FF00',
  limegreen: '#32CD32',
  linen: '#FAF0E6',
  magenta: '#FF00FF',
  maroon: '#800000',
  mediumaquamarine: '#66CDAA',
  mediumblue: '#0000CD',
  mediumorchid: '#BA55D3',
  mediumpurple: '#9370DB',
  mediumseagreen: '#3CB371',
  mediumslateblue: '#7B68EE',
  mediumspringgreen: '#00FA9A',
  mediumturquoise: '#48D1CC',
  mediumvioletred: '#C71585',
  midnightblue: '#191970',
  mintcream: '#F5FFFA',
  mistyrose: '#FFE4E1',
  moccasin: '#FFE4B5',
  navajowhite: '#FFDEAD',
  navy: '#000080',
  oldlace: '#FDF5E6',
  olive: '#808000',
  olivedrab: '#6B8E23',
  orange: '#FFA500',
  orangered: '#FF4500',
  orchid: '#DA70D6',
  palegoldenrod: '#EEE8AA',
  palegreen: '#98FB98',
  paleturquoise: '#AFEEEE',
  palevioletred: '#DB7093',
  papayawhip: '#FFEFD5',
  peachpuff: '#FFDAB9',
  peru: '#CD853F',
  pink: '#FFC0CB',
  plum: '#DDA0DD',
  powderblue: '#B0E0E6',
  purple: '#800080',
  red: '#FF0000',
  rosybrown: '#BC8F8F',
  royalblue: '#4169E1',
  saddlebrown: '#8B4513',
  salmon: '#FA8072',
  sandybrown: '#F4A460',
  seagreen: '#2E8B57',
  seashell: '#FFF5EE',
  sienna: '#A0522D',
  silver: '#C0C0C0',
  skyblue: '#87CEEB',
  slateblue: '#6A5ACD',
  slategray: '#708090',
  slategrey: '#708090',
  snow: '#FFFAFA',
  springgreen: '#00FF7F',
  steelblue: '#4682B4',
  tan: '#D2B48C',
  teal: '#008080',
  thistle: '#D8BFD8',
  tomato: '#FF6347',
  turquoise: '#40E0D0',
  violet: '#EE82EE',
  wheat: '#F5DEB3',
  whitesmoke: '#F5F5F5',
  yellowgreen: '#9ACD32',
};

export const proportionToHex = num => {
  if (num > 1 || num < 0) {
    throw new Error('A value of 0-1 is required for hex conversion');
  }
  let output = Math.ceil(num * 255)
    .toString(16)
    .toUpperCase();
  if (output.length === 1) output = `0${output}`;
  return output;
};

export const getHexValuesInString = (str = '') => {
  if (!str) return [];
  if (typeof str !== 'string') return [];

  const regex = /#[a-z0-9]*/gi;
  return str.match(regex);
};

/**
 * Converts a hex color to HSL and increases the lightness by a specified percentage
 * @param {string} hex - The hex color to convert (e.g. '#3366cc' or '#36c')
 * @param {number} amount - The percentage to increase lightness by (default: 5)
 * @returns {string} - The lightened color in HSL format
 */
export const lightenColor = (hex, amount = 5) => {
  // Remove the hash if it exists
  hex = hex.replace('#', '');

  // Convert 3-digit hex to 6-digit
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16) / 255;
  const g = parseInt(hex.substring(2, 4), 16) / 255;
  const b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values to calculate saturation
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate HSL values
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    // Achromatic (gray)
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  // Convert to degrees, percentages
  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  // Increase lightness by the specified amount, capping at 100%
  l = Math.min(100, l + amount);

  // Return HSL color string
  return `hsl(${h}, ${s}%, ${l}%)`;
};

//! This class is a work in progress, do not use until this comment is removed
class Color {
  constructor(input, alpha, { defaultAlpha = 1 }) {
    this.input = input;
    this.defaultAlpha = defaultAlpha;
    if (alpha !== null && alpha !== undefined) {
      if (alpha < 0) this.alpha = 0;
      else if (alpha > 1) this.alpha = 1;
      else this.alpha = alpha;
    } else {
      this.alpha = defaultAlpha;
    }
    this.conversion = this.convertColor(this.input, alpha);
  }

  numToHex(num, use255 = false) {
    if (num === null || num === undefined) return null;
    if (num < 0 || use255 === false ? num > 1 : num > 255)
      throw new Error('A value of 0-1 or 0-255 is required for hex conversion');
    let output = Math.ceil(num * (use255 ? 1 : 255))
      .toString(16)
      .toUpperCase();
    if (output.length === 1) output = `0${output}`;
    return output;
  }

  toNearestCssColor(rgba = []) {
    return Object.entries(cssColors).reduce((p, [color, hexValue]) => {
      let output = p;
      const cssColor = hexValue.replace(/#/gim, '').toUpperCase();
      const [r, g, b] = this.hexToRgba(cssColor);

      const diff = [
        Math.max(r, rgba[0]) - Math.min(r, rgba[0]),
        Math.max(g, rgba[1]) - Math.min(g, rgba[1]),
        Math.max(b, rgba[2]) - Math.min(b, rgba[2]),
      ].reduce((p, c) => {
        let out = p;
        out += c;
        return out;
      }, 0);

      if (output === null || diff < p.diff) {
        output = {
          color,
          rgba: [r, g, b, rgba[rgba.length - 1]],
          diff,
        };
      }
      return output;
    }, null);
  }

  // Todo replace alphas to use class alpha when needed
  rgbaToHex(rgba = []) {
    const [r, g, b, a] = rgba;
    const red = r.toString(16).padStart(2, r.toString(16));
    const green = g.toString(16).padStart(2, g.toString(16));
    const blue = b.toString(16).padStart(2, b.toString(16));
    let alpha;

    if (a !== undefined && a !== 1) {
      const alphaHex = Math.round(a * 255).toString(15);
      alpha = alphaHex.padStart(2, alphaHex);
    }

    return `#${red}${green}${blue}${alpha}`.toUpperCase();
  }

  hexToRgba(hex, alpha, outputAsCss = false) {
    const realHex = hex.replace(/#/gim, '');
    let fullHex = null;
    const rgba = {
      r: 0,
      g: 0,
      b: 0,
      a: alpha,
    };

    if (realHex.length === 3)
      fullHex =
        hex[0].repeat(2) +
        hex[1].repeat(2) +
        hex[2].repeat(2) +
        this.numToHex(alpha);
    else if (realHex.length === 4)
      fullHex =
        hex[0].repeat(2) +
        hex[1].repeat(2) +
        hex[2].repeat(2) +
        (this.numToHex(alpha) ?? hex[3].repeat(2));
    else if (realHex.length === 6) fullHex = hex + this.numToHex(alpha);
    else if (realHex.length === 8)
      fullHex =
        realHex.slice(0, 6) + this.numToHex(alpha) ?? realHex.slice(6, 8);

    if (fullHex) {
      const hexRgb = fullHex.slice(0, 6);
      const alpha = fullHex.slice(6, 8);
      const bigInt = parseInt(hexRgb, 16);
      rgba.r = (bigInt >> 16) & 255;
      rgba.g = (bigInt >> 8) & 255;
      rgba.b = bigInt & 255;
      rgba.a = parseFloat((parseInt(alpha, 16) / 255).toFixed(2));
    }

    if (outputAsCss) {
      return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
    }
    return Object.values(rgba);
  }

  rgbaToHsla(rgba = []) {
    const [r, g, b, a] = rgba.map((value, index) =>
      index < 3 ? value / 255 : value
    );
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;
    let h;
    let s;
    let l;

    // Calculate Hue
    if (delta === 0) h = 0;
    else if (max === r) h = ((g - b) / delta) % 6;
    else if (max === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;
    h = Math.round(h * 60);
    if (h < 0) h += 360;

    // Calculate Lightness
    l = (max + min) / 2;

    // Calculate Saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return `hsla(${h}, ${s}%, ${l}%, ${a})`;
  }

  convertColor(input, alpha) {
    const cleanInput = input.trim().toUpperCase().replace(/;/gim, '');
    const isRgb =
      /rgb(a)?\([0-9]+,\s*[0-9]+,\s*[0-9]*(,\s*[0-9]+.?[0-9]*)?\)/gim;
    const isHex = /#[a-fA-F0-9]*/gim;
    const isHsl =
      /hsl(a)?\([0-9]+,\s*[0-9]+%,\s*[0-9]*%(,\s*[0-9]+.?[0-9]*)?\)/gim;
    const rgba = {
      r: 0,
      g: 0,
      b: 0,
      a: 0,
    };

    // If RGB
    if (cleanInput.match(isRgb)) {
      const [r, g, b, a] = cleanInput
        .replace(/[(rgb(a)?)()%\s]/gim, '')
        .trim()
        .split(',');
      rgba.r = parseInt(r);
      rgba.g = parseInt(g);
      rgba.b = parseInt(b);
      rgba.a = parseFloat(alpha ?? a ?? this.defaultOpacity);
    }

    // If Hex
    if (cleanInput.match(isHex)) {
      const hex = cleanInput.replace(/#/gim, '');
      let fullHex = null;

      if (hex.length === 3)
        fullHex =
          hex[0].repeat(2) +
          hex[1].repeat(2) +
          hex[2].repeat(2) +
          this.numToHex(alpha);
      else if (hex.length === 4)
        fullHex =
          hex[0].repeat(2) +
          hex[1].repeat(2) +
          hex[2].repeat(2) +
          (this.numToHex(alpha) ?? hex[3].repeat(2));
      else if (hex.length === 6) fullHex = hex + this.numToHex(alpha);
      else if (hex.length === 8)
        fullHex = hex.slice(0, 6) + this.numToHex(alpha) ?? hex.slice(6, 8);

      if (fullHex) {
        const hexRgb = fullHex.slice(0, 6);
        const alpha = fullHex.slice(6, 8);
        const bigInt = parseInt(hexRgb, 16);
        rgba.r = (bigInt >> 16) & 255;
        rgba.g = (bigInt >> 8) & 255;
        rgba.b = bigInt & 255;
        rgba.a = parseFloat((parseInt(alpha, 16) / 255).toFixed(2));
      }
    }

    // If HSL
    if (cleanInput.match(isHsl)) {
      let [h, s, l, a] = cleanInput
        .replace(/[(hsl(a)?)()%\s]/gim, '')
        .trim()
        .split(',');
      h = parseInt(h);
      s = parseInt(s) / 100;
      l = parseInt(l) / 100;
      a = parseFloat(a);

      const k = num => (num + h / 30) % 12;
      const f = num =>
        l -
        s *
          Math.min(l, 1 - l) *
          Math.max(-1, Math.min(k(num) - 3, Math.min(9 - k(num), 1)));
      rgba.r = Math.round(255 * f(0));
      rgba.g = Math.round(255 * f(8));
      rgba.b = Math.round(255 * f(4));
      rgba.a = parseFloat(parseFloat(a).toFixed(2));
    }

    // Implement Colors
    if (cssColors[cleanInput.toLowerCase()]) {
      const hex = cssColors[cleanInput.toLowerCase()].replace(/#/gim, '');
      const [r, g, b, a] = this.hexToRgba(hex);
      rgba.r = r;
      rgba.g = g;
      rgba.b = b;
      rgba.a = a;
    }

    // Return an object containing toHex(short), toRgb(), toHsl(), toNearestCssColor()
    return {
      values: rgba,
      hex: this.rgbaToHex(Object.values(rgba)),
      rgb: `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`,
      hsl: this.rgbaToHsla(Object.values(rgba)),
      nearestCssColor: this.toNearestCssColor(Object.values(rgba)),
    };
  }

  get values() {
    return this.conversion.values;
  }
  get hex() {
    return this.conversion.hex;
  }
  get rgb() {
    return this.conversion.rgb;
  }
  get hsl() {
    return this.conversion.hsl;
  }
  get nearestCssColor() {
    return this.conversion.nearestCssColor;
  }
}

export default Color;
