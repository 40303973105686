import styled from 'styled-components';

export default styled.div`
 display: flex;
 width: 100%;
 
 picture {
    width: 100%;
    position: relative;
    z-index: 4;
 }

 .hero-video-wrapper {
    display: flex; 
    width: 100%;

    .hero-video{
        display: flex;
        width: 100%;
        picture {
            width: 100%;
            height: 100%;
            position: relative;
            display: block;
            
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
           
            button {
                cursor: pointer;
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%, -50%);
    
              svg{
                width: 100%;
              }
            }
        }    
     }
 }
 
`