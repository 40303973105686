import React from 'react';
import IndividualLicenseMapSection from './IndividualLicenseMapSection';
import TeamLicenseMapSection from './TeamLicenseMapSection';

const LicenseMapSection = ({
  $data,
  className,
  isIndividual = false,
  businessCards = [],
  showTooltips = false,
}) => {
  if (isIndividual) {
    return (
      <IndividualLicenseMapSection
        $data={$data}
        businessCards={businessCards}
        className={className}
        showTooltips={showTooltips}
      />
    );
  }

  return (
    <TeamLicenseMapSection
      $data={$data}
      businessCards={businessCards}
      className={className}
    />
  );
};

export default LicenseMapSection;
