import React from 'react';
import ReactMarkdown from 'react-markdown';
import InView from '../../../Shared/InView';
import Styles from './HeroSection.styles';
import VisualMedia from '../VisualMedia/VisualMedia';
import Link from '../Link';

const HeroSection = ({ $data }) => {
  return (
    <InView>
      <Styles $data={$data}>
        <div className='hero-section'>
          <div className='hero-content'>
            <ReactMarkdown source={$data?.heroContent?.header} />

            <ReactMarkdown source={$data?.heroContent?.intro} />
            <div className='intro-links'>
              {$data.heroContent?.introLinks &&
                $data?.heroContent?.introLinks.map((link, index) => (
                  <Link key={`intro-link-${index}`} $data={link} />
                ))}
            </div>
            <ReactMarkdown source={$data?.heroContent?.disclaimer} />
          </div>
          <div className='hero-image'>
            {$data.heroImage && (
              <VisualMedia $data={$data.heroImage}></VisualMedia>
            )}
          </div>
        </div>
      </Styles>
    </InView>
  );
};

export default HeroSection;
