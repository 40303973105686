import styled from 'styled-components';

export default styled.section`
  > header {
    text-align: center;
    border-bottom: solid 1px ${props => props.theme.colors.grey4};
    padding: 0.75rem 0;
    margin-top: 5.5rem;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      margin-top: 3rem;
    }

    h2 {
      margin: 0;
    }
  }

  .content {
    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    > header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.25rem;
      position: relative;

      .label {
        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          text-align: center;
        }

        h3 {
          font-size: ${props => props.theme.type.h2Mobile};
          margin: 0;
          color: ${props => props.theme.colors.claytonBlue};

          @media (min-width: ${props => props.theme.breakpoints.desktop}) {
            font-size: ${props => props.theme.type.h1};
          }
        }

        h4 {
          font-size: ${props => props.theme.type.label.large};
          margin: 0;
          color: ${props => props.theme.colors.grey1};
          text-transform: uppercase;
          letter-spacing: ${props => props.theme.letterSpacing.label};
        }
      }

      .chart {
        width: 96px;

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          width: 274px;
        }
      }
    }

    ul {
      list-style: none;
      margin: 2rem 0 0;
      padding: 0;
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        margin-left: 54px;
      }

      li {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          flex-wrap: wrap;
          padding: 0 0 1.5rem;
        }

        .color {
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 100%;
          margin-right: 0.75rem;
          margin-top: 0.1rem;

          @media (min-width: ${props => props.theme.breakpoints.desktop}) {
            margin-top: 0.25rem;
          }
        }

        .title {
          font-size: ${props => props.theme.type.caption};
          color: ${props => props.theme.colors.grey1};
          font-weight: 100;
          margin: 0;
          flex-grow: 1;
        }

        .value {
          font-size: ${props => props.theme.type.h5};
          color: ${props => props.theme.colors.bedtime};
          font-weight: 800;
          margin: 0;

          @media (min-width: ${props => props.theme.breakpoints.desktop}) {
            width: 100%;
            margin-left: 1.625rem;
          }
        }
      }
    }
  }
`;
