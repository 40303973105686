import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getColors, loadModel } from 'vmfcom-lib/utils';
import Styles from './TextSection.styled';
import InView from '../../../Shared/InView/InView';
import theme from '../../../../styles/theme';
import PlayVideoIcon from '../../../Shared/Icons/PlayVideo';
import Picture from '../../../Shared/Picture';
import Modal from '../../../Shared/Modal';
import Video from '../../../Shared/Video';
import sa from '../../../../styles/Components/SimpleAnimation';
import Link from '../Link';
import createClassList from '../../../../util/createClassList';

const contains = (arr1, arr2) => arr1.some(s => arr2.includes(s));

const TextSection = ({
  $data = {},
  className = '',
  onVideoPlay = () => {},
}) => {
  const primaryClass = 'text-section';
  const content = loadModel($data, 'VMFCom', 'TextSection');

  const [modalOpen, setModalOpen] = useState(false);
  const videoThumbnail = useMemo(
    () => ({
      src: content?.sectionMedia?.image?.file?.url,
      alt: content?.sectionMedia?.image?.description,
    }),
    [content]
  );

  const imageLocation = useMemo(() => {
    const leftSide = ['Top Left', 'Middle Left', 'Bottom Left', 'Left Side'];
    const rightSide = [
      'Top Right',
      'Middle Right',
      'Bottom Right',
      'Right Side',
    ];
    const all = [
      ...(content?.imageLocation || []),
      ...(content?.sectionMediaPosition || []),
    ].flat();
    if (contains(all, leftSide)) return 'Left Side';
    if (contains(all, rightSide)) return 'Right Side';
    return 'Background';
  }, [content?.imageLocation, content?.sectionMediaPosition]);

  const showVideo = useMemo(
    () => content?.sectionMedia?.videoId && content?.sectionMedia?.videoSource,
    [content?.sectionMedia]
  );
  const mediaFloatLeft = useMemo(
    () =>
      content?.sectionMediaPosition &&
      ['Top Left', 'Middle Left', 'Bottom Left'].includes(
        content.sectionMediaPosition
      )
  );
  const handlePlayClick = () => {
    if (onVideoPlay) onVideoPlay();
    setModalOpen(true);
  };

  return (
    <InView>
      <Styles
        className={createClassList(primaryClass, className)}
        primaryClass={primaryClass}
        $data={content}
        headerColor={
          getColors(content?.headerTextColor).primary || theme.colors.white
        }
        subHeaderColor={
          getColors(content?.subHeaderTextColor).primary || theme.colors.white
        }
        introColor={
          getColors(content?.introTextColor).primary || theme.colors.white
        }
        showVideo={showVideo}
        labelColor={
          getColors(content?.labelTextColor).primary || theme.colors.white
        }
        disclaimerColor={
          getColors(content?.disclaimerTextColor).primary || theme.colors.white
        }
      >
        <div className={`${primaryClass}__inner`}>
          <sa.div
            className={`${primaryClass}__media`}
            slideDirection='up'
            delay={!mediaFloatLeft ? 0.4 : 0.2}
          >
            {showVideo ? (
              <div className={`${primaryClass}__media__thumbnail`}>
                <Picture
                  src={videoThumbnail.src}
                  alt={videoThumbnail.alt}
                  sources={[
                    {
                      media: `(min-width: ${theme.breakpoints.desktop})`,
                      params: '?w=626',
                      srcSet: videoThumbnail.src,
                    },
                    {
                      media: `(min-width: ${theme.breakpoints.tablet})`,
                      params: '?w=512',
                      srcSet: videoThumbnail.src,
                    },
                    {
                      media: `(max-width: ${theme.breakpoints.tablet})`,
                      params: '?w=300',
                      srcSet: videoThumbnail.src,
                    },
                  ]}
                />
                <button
                  className={`${primaryClass}__media__thumbnail__play-btn`}
                  onClick={handlePlayClick}
                >
                  <PlayVideoIcon
                    backgroundColor={
                      getColors(content?.sectionBackgroundColor)?.primary
                    }
                    color={theme.colors.summerNight} // TODO Contentful Replacement Planned
                  />
                </button>
              </div>
            ) : content?.sectionMedia?.image?.file?.url ? (
              <div className={`${primaryClass}__media__thumbnail`}>
                <Picture
                  src={content?.sectionMedia?.image?.file?.url}
                  alt={content?.sectionMedia?.image?.description}
                  isResponsive={true}
                  sources={[
                    { media: '(max-width: 640px)', params: '?w=640' },
                    { media: '(max-width: 1000px)', params: '?w=1000' },
                    { media: '(max-width: 1400px)', params: '?w=1400' },
                  ]}
                />
              </div>
            ) : (
              <></>
            )}
          </sa.div>
          <div className={`${primaryClass}__content`}>
            {content?.sectionLabel && (
              <sa.div
                className={`${primaryClass}__content__label`}
                slideDirection='up'
                delay={!mediaFloatLeft ? 0.2 : 0.4}
              >
                <ReactMarkdown source={content?.sectionLabel} />
              </sa.div>
            )}
            {content?.sectionHeader && (
              <sa.h2
                className={`${primaryClass}__content__heading`}
                slideDirection='up'
                delay={imageLocation === 'Right Side' ? 0.2 : 0.4}
              >
                {content?.sectionHeader}
              </sa.h2>
            )}
            {content?.sectionSubHeader && (
              <sa.h3
                className={`${primaryClass}__content__sub-heading`}
                slideDirection='up'
                delay={imageLocation === 'Right Side' ? 0.4 : 0.6}
              >
                {content?.sectionSubHeader}
              </sa.h3>
            )}
            {content?.sectionIntro && (
              <sa.div
                className={`${primaryClass}__content__intro`}
                slideDirection='up'
                delay={!mediaFloatLeft ? 0.6 : 0.8}
              >
                <ReactMarkdown
                  source={content?.sectionIntro}
                  parserOptions={{ commonmark: true }}
                />
              </sa.div>
            )}
            {content?.sectionLinks?.length && (
              <div className={`${primaryClass}__content__links`}>
                {content.sectionLinks.map((link, index) => {
                  const animDelay = content?.sectionIntro
                    ? 0.4 + 0.2 * (index + 2)
                    : 0.2 * (index + 2);
                  return (
                    <sa.div
                      key={`text-section-links-${index}`}
                      slideDirection='up'
                      delay={animDelay}
                    >
                      <Link $data={link} />
                    </sa.div>
                  );
                })}
              </div>
            )}
            {content?.disclaimer && (
              <sa.div
                slideDirection='up'
                className={`${primaryClass}__content__disclaimer`}
              >
                <ReactMarkdown
                  source={content?.disclaimer}
                  renderers={{
                    link: ({ children, href, title }) => {
                      return (
                        <Link
                          $data={{
                            link: href,
                            displayLinkAs: 'Text',
                            linkText: children,
                          }}
                        />
                      );
                    },
                  }}
                />
              </sa.div>
            )}
          </div>
        </div>
        {showVideo && modalOpen && (
          <Modal onClose={() => setModalOpen(false)}>
            <Video
              content={content?.sectionMedia?.videoId}
              source={content?.sectionMedia?.videoSource}
              autoplay={true}
            />
          </Modal>
        )}
      </Styles>
    </InView>
  );
};

export default TextSection;
