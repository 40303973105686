import styled from 'styled-components'
import Scrollable from '../Scrollable'

const PickerStyles = styled(Scrollable)`
  * {
    box-sizing: border-box;
  }

  display: none;

  @media(max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }

  .picker-item {
    box-sizing: border-box;
    padding: 0 .5rem;
    color: black;
    display: flex;
    align-items: center;
    height: 34px;
  }

`
export default PickerStyles