import React from 'react';
import theme from '../../../styles/theme';

export default ({ active = false }) => (
  <svg
    width='15'
    height='14'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='7.95312'
      cy='7'
      r='7'
      fill={active ? theme.colors.claytonBlue : theme.colors.blue20}
    />
    <path
      d='M7.28067 9.15849H8.69302V10.5282H7.28067V9.15849ZM5.55078 5.74611C5.5887 4.84562 5.90308 4.20738 6.49393 3.83139C6.86676 3.59126 7.3249 3.47119 7.86836 3.47119C8.58243 3.47119 9.17486 3.64181 9.64564 3.98305C10.1196 4.32429 10.3566 4.82982 10.3566 5.49966C10.3566 5.91041 10.2539 6.25639 10.0485 6.53759C9.92842 6.70821 9.69777 6.92623 9.35653 7.19163L9.02004 7.4523C8.83678 7.59448 8.71513 7.76036 8.6551 7.94994C8.61718 8.07001 8.59665 8.25642 8.59349 8.50919H7.31384C7.3328 7.97522 7.38336 7.60712 7.46551 7.40491C7.54766 7.19953 7.75935 6.96414 8.10059 6.69873L8.44657 6.42859C8.56031 6.34328 8.65194 6.25007 8.72145 6.14896C8.84784 5.97518 8.91103 5.78403 8.91103 5.57549C8.91103 5.33536 8.83994 5.11735 8.69775 4.92145C8.55873 4.7224 8.3028 4.62287 7.92997 4.62287C7.56345 4.62287 7.30279 4.74451 7.14796 4.9878C6.9963 5.23109 6.92047 5.48386 6.92047 5.74611H5.55078Z'
      fill={active ? theme.colors.white : theme.colors.bedtime}
    />
  </svg>
);
