import styled from 'styled-components';

const maxWidth = '1222px';

export default styled.section`${({ theme, hasSubHeader, hasFooter, backgroundColor, headerTextColor }) => `
  box-sizing: border-box;
  padding: 4.7206rem ${theme.padding.inner} 2rem ${theme.padding.inner};
  background-color: ${backgroundColor || theme.colors.white};

  @media (max-width: ${theme.breakpoints.tablet}) {
    padding: 3.375rem ${theme.padding.inner} 3rem ${theme.padding.inner};
  }

  .calculator-section-header, .calculator-section-sub-header {
    text-align: center;
    max-width: ${maxWidth};
    margin: auto;
  }

  .calculator-section-header {
    margin-bottom: ${hasSubHeader ? '0.9444rem' : '2.25rem'};
    ${headerTextColor ? `color: ${headerTextColor}` : ''};
  }

  .calculator-section-sub-header {
    margin-bottom: 2.25rem;
  }

  .calculator-section-footer {
    max-width: ${maxWidth};
    margin: auto;

    p {
      font-size: ${theme.type.paragraph.xs};
      line-height: ${theme.lineHeights.paragraph.xs};
      font-family: ${theme.fonts.primary};
      font-weight: normal; 
    }
  }

  .calculator-section-calculator {
    padding: 0;
    margin-bottom: ${hasFooter ? '2.75rem' : 0};

    .inner {
      max-width: ${maxWidth};

      .calculator .results {
        margin-right: 0;
      }
        
    }
  }
`}`;
