import React from 'react';
import { getColors, loadModel } from 'vmfcom-lib/utils';
import Styles from './PromoLPTemplate.styled';
import SEO from '../../components/SEO/SEO';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import ErrorComponent from '../../components/Error/Error';
import { getLpTemplate } from '../../services/contentful';
import MainContent from '../../components/Contentful/v2/MainContent/MainContent';
import FourOhFour from '../../components/FourOhFour/FourOhFour';

const PromoLPTemplate = ({ data, err, isLoading }) => {
  if (err) {
    console.error(err);
    return (
      <Layout>
        <ErrorComponent />
      </Layout>
    );
  }

  if (data === undefined) {
    return (
      <Layout>
        <FourOhFour />
      </Layout>
    );
  }

  const entry = loadModel(data, 'VMFCom', 'LpTemplate');
  if (isLoading) return null;

  return (
    <Layout isLoading={isLoading}>
      <SEO title={entry?.titleTag} description={entry?.metaDescription} />
      <Loading isLoading={isLoading} />
      <Styles>
        <MainContent entries={entry?.mainContent} />

        {/* Pre Footer CTA (actions) */}
      </Styles>
    </Layout>
  );
};

PromoLPTemplate.getInitialProps = async ({ match }) => {
  const pageUrl = match.url;
  if (typeof window === 'undefined') {
    try {
      const data = await getLpTemplate(`${pageUrl}`);
      return { data: data };
    } catch (err) {
      return {
        err,
      };
    }
  } else {
    window.location.reload();
  }
};

export default PromoLPTemplate;
