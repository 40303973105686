import React from 'react';

const SMS = ({ size = 30, color = null, strokeClass = '', ...props }) => {
  return (
    <svg
      width={size * 1.1}
      height={size}
      viewBox='0 0 33 30'
      fill='none'
      {...props}
    >
      <path
        d='M11.8125 24.3736V22.8736H10.3125H4.125C2.54041 22.8736 1.5 21.7322 1.5 20.6238V3.74978C1.5 2.64138 2.54041 1.5 4.125 1.5H28.875C30.4596 1.5 31.5 2.64138 31.5 3.74978V20.6238C31.5 21.7322 30.4596 22.8736 28.875 22.8736H19.5938H19.131L18.7486 23.1343L11.8125 27.8645V24.3736Z'
        stroke={color}
        strokeWidth='3'
        className={strokeClass}
      />
    </svg>
  );
};

export default SMS;
