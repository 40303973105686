import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { loadModel } from 'vmfcom-lib/utils';
import InView from '../../../Shared/InView';
import Link from '../Link';
import Styles from './CTA.styled';
import sa from '../../../../styles/Components/SimpleAnimation';
import theme from '../../../../styles/theme';
import Picture from '../../../Shared/Picture';
import createClassList from '../../../../util/createClassList';

const CTA = ({ $data, className, borders = false }) => {
  const primaryClass = 'cta';
  const content = loadModel($data, 'VMFCom', 'Cta');

  const classes = useMemo(
    () => createClassList(primaryClass, className),
    [className]
  );

  const image = useMemo(
    () => ({
      location: content?.ctaImageLocation,
      desktop: {
        src: content?.ctaImage?.file?.url,
        alt: content?.ctaImage?.description,
      },
      mobile: {
        src: content?.ctaMobileImage?.file?.url,
        alt: content?.ctaMobileImage?.description,
      },
    }),
    [content]
  );

  return (
    <InView>
      <Styles primary={primaryClass} $data={content} borders={borders}>
        <div className={classes}>
          <div className={`${primaryClass}__inner`}>
            <div className={`${primaryClass}__image`}>
              {image?.desktop?.src && image?.location === 'Top' && (
                <div>
                  <Picture
                    className='down-on-homes-image'
                    src={image?.desktop?.src}
                    alt={image?.desktop?.alt}
                    sources={[
                      {
                        media: `(min-width: ${theme.breakpoints.desktop})`,
                        params: '?w=626',
                        srcSet: image?.desktop?.src,
                      },
                      {
                        media: `(min-width: ${theme.breakpoints.tablet})`,
                        params: '?w=512',
                        srcSet: image?.desktop?.src,
                      },
                      {
                        media: `(max-width: ${theme.breakpoints.tablet})`,
                        params: '?w=300',
                        srcSet: image?.mobile?.src || image?.desktop?.src,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <div className={`${primaryClass}_content`}>
              <sa.h2 className={`${primaryClass}__heading`} slideDirection='up'>
                {content?.ctaHeader}
              </sa.h2>
              {content?.ctaIntro && (
                <sa.p
                  className={`type-intro ${primaryClass}__intro`}
                  slideDirection='up'
                  delay={0.4}
                >
                  <ReactMarkdown source={content?.ctaIntro} />
                </sa.p>
              )}
              <div className={`${primaryClass}__links`}>
                {content?.ctaLinks?.map((link, index) => {
                  const animDelay = content?.ctaIntro
                    ? 0.4 + 0.2 * (index + 2)
                    : 0.2 * (index + 2);

                  return (
                    <sa.div
                      key={`cta-custom-link-${index}`}
                      slideDirection='up'
                      delay={animDelay}
                    >
                      <Link $data={link} />
                    </sa.div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {image?.desktop?.src && image?.location === 'Center' && (
          <div className={`${primaryClass}__background-image`}>
            <Picture
              src={image?.desktop?.src}
              alt={image?.desktop?.alt}
              sources={[
                {
                  media: `(min-width: ${theme.breakpoints.desktop})`,
                  params: '?w=626',
                  srcSet: image?.desktop.src,
                },
                {
                  media: `(min-width: ${theme.breakpoints.tablet})`,
                  params: '?w=512',
                  srcSet: image?.desktop?.src,
                },
                {
                  media: `(max-width: ${theme.breakpoints.tablet})`,
                  params: '?w=300',
                  srcSet: image?.mobile?.src || image?.desktop?.src,
                },
              ]}
            />
          </div>
        )}
      </Styles>
    </InView>
  );
};

export default CTA;
