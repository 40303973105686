import styled from 'styled-components';

export default styled.div`
  position: absolute;
  width: max-content;
  max-width: 15rem;
  overflow-wrap: break-word;
  pointer-events: none;
  opacity: 0;
  animation-duration: ${props => (props.animationDuration / 1000).toFixed(1)}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: normal;
  z-index: 900;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  &.tooltip {
    &--in {
      animation-name: fadeIn;
    }

    &--out {
      animation-name: fadeOut;
    }
  }

  .tooltip {
    &__content {
      text-align: ${props => props.textAlign};
      background-color: ${props => props.backgroundColor};
      color: ${props => props.textColor};
      z-index: 300;
      position: relative;
      border-radius: 4px;
      padding: .6rem 1rem;
      font-weight: 400;
      font-size: 0.875rem !important;
      ${props => props.boxShadow ? 'box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);' : ''}
      &__heading {
        font-weight: 700;
        color: ${props => props.headingColor}; 
      }
    }

    &__caret {
      display: block;
      position: relative;
      z-index: 350;

      &--top {
        transform: rotate(180deg);
        top: 6px;
      }

      &--bottom {
        top: -6px;
      }
    }
  }
`;
